import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrl: './app-footer.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AppFooterComponent {

}
