import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LineItems, PaymentRequest } from '../models/payment';
import { Livestock, Registration, RegistrationRequest, RegistrationResponse } from '../models/registration';
import { RegistrationService } from '../registration.service';
import { NotificationService } from '../../shared/service/notification.service';
import CommonUtils from '../../shared/utils/common.utils';
import { OverlayPanel } from 'primeng/overlaypanel';

export function expiryDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null; // Return null if there's no value to validate

    const [month, year] = value.split('/').map(Number);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed
    const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year

    // Check if the year is in the future or if it is the current year but the month is valid
    if (year > currentYear || (year === currentYear && month >= currentMonth)) {
      return null; // Valid future date
    }

    return { expired: true }; // Invalid date (expired)
  };
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent {

  private registrationService = inject(RegistrationService);
  private notificationService = inject(NotificationService);
  private changeDetector = inject(ChangeDetectorRef);
  private fb = inject(FormBuilder);

  @Input() w9File: File | undefined;
  @Input() registration: Registration = {};
  @Input() orgId: number = 0;
  @Input() registrationResponse!: RegistrationResponse | undefined;

  @Output() previousClick = new EventEmitter();
  @Output() onPaymentComplete = new EventEmitter<RegistrationResponse>();

  processingPayment: boolean = false;

  paymentForm: FormGroup;
  liveStocks: Livestock[] = [];
  liveStocksByDept = new Map<string, Livestock[]>();

  constructor() {
    this.paymentForm = this.fb.group({
      cardNumber: [],
      expirationDate: [],
      cardCode: [],
      nameOnCard: [],
      billingZipCode: []
    });
  }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      //cardNumber: [null, [Validators.required, Validators.pattern(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12}|(2131|1800|35\d{3})\d{11})$/)]],
      cardNumber: [null, [Validators.required]],
      expirationDate: [null, [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/), expiryDateValidator()]],
      cardCode: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      nameOnCard: [null, [Validators.required]],
      billingZipCode: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]]
    });

    if (this.registration.liveStocks) {
      this.liveStocks = this.registration.liveStocks;

      this.liveStocks.forEach(ls => {
        var i = this.liveStocksByDept.get(ls.department);

        if (!i) {
          i = new Array<Livestock>;
        }

        i.push(ls);

        this.liveStocksByDept.set(ls.department, i);
      });
    }
  }

  get selectedLivestocks() {
    return Array.from(this.liveStocksByDept.entries());
  }

  onPay(): void {
    this.paymentForm.markAllAsTouched();
    this.paymentForm.updateValueAndValidity();
    this.changeDetector.detectChanges();

    if (this.paymentForm.valid) {
      this.processingPayment = true;

      const payment: PaymentRequest = {
        creditCard: {
          cardNumber: this.paymentForm.controls['cardNumber'].value,
          expirationDate: this.paymentForm.controls['expirationDate'].value,
          cardCode: this.paymentForm.controls['cardCode'].value,
        },
        customerAddress: {
          firstName: this.registration.firstName ?? '',
          lastName: this.registration.lastName ?? '',
          address: this.registration.address ?? '',
          city: this.registration.city ?? '',
          state: this.registration.state ?? '',
          zip: this.paymentForm.controls['billingZipCode'].value
        }
      };

      const lineItems: LineItems[] = [];

      this.registration.liveStocks?.forEach(ls => {
        if (ls.heiferId == -1) {
          ls.heiferId = undefined;
          // ls.qualityCount = undefined;
        }

        lineItems.push({
          //itemId: ls.subClassId ? `${ls.classId}_${ls.subClassId}` : `${ls.classId}`,
          itemId: ls.classId,
          subClassId: ls.subClassId,
          name: ls.subClass ?? ls.class,
          quantity: ls.count,
          unitPrice: ls.priceEach
        });
      });

      payment.lineItems = lineItems;

      const reqRequest: RegistrationRequest = {
        exhibitorsSellers: this.registration,
        authorizationDetails: payment
      }

      this.registrationService.payAndRegister(reqRequest, this.w9File)
        .then(response => {
          //this.registrationResponse = response;
          this.onPaymentComplete.emit(response);
          this.notificationService.clear();
        })
        .catch(err => {
          console.error(err);
          if (err.error && err.error.errorCode == 1000) {
            this.notificationService.error("Due to technical issue, payment has not been attepmted. Please try again.", true);
          } else if (err.error && err.error.response) {
            this.notificationService.error(err.error.response.errorText ?? err.error.response.messageText ?? "Payment failed! Please try again.", true);
          } else {
            this.notificationService.error("Payment failed! Please try again.", true);
          }
          this.paymentForm.reset();
        }).finally(() => {
          this.processingPayment = false;
        });
    }
  }

  onPreviousClick() {
    this.previousClick.emit();
  }

  async onPrintConfirmation() {
    if (this.registrationResponse) {
      try {
        const response = await this.registrationService.downloadConfirmation(this.orgId, this.registrationResponse.exhibitor.id);
        //const response = await this.registrationService.downloadConfirmation(6, 160);
        const blobUrl = URL.createObjectURL(response.file);

        // Open in a new tab
        window.open(blobUrl, '_blank');
      } catch (e: any) {
        console.error(e);
        this.notificationService.error();
      }
    }
  }

  async onDownloadConfirmation() {
    if (this.registrationResponse) {
      try {
        const response = await this.registrationService.downloadConfirmation(this.orgId, this.registrationResponse.exhibitor.id);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.file);
        link.download = response.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (e: any) {
        console.error(e);
        this.notificationService.error();
      }
    }
  }

  showErrorMessage(formControlName: string): boolean {
    const fc = this.paymentForm.controls[formControlName];

    return (fc.touched || fc.dirty) && fc.invalid;
  }

  getSubClassName = CommonUtils.getSubClassName;

  @ViewChildren(OverlayPanel) overlayPanels!: QueryList<OverlayPanel>;

  showOverlayPanel(event: Event, index: number) {
    const overlayPanel = this.overlayPanels.toArray()[index];
    if (overlayPanel) {
      overlayPanel.toggle(event);
    }
  }

}
