import { inject, Injectable } from '@angular/core';
import _states from '../../api/us-states.json';
import { firstValueFrom, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LiveStockSummary } from './models/liveStockSummary';
import { ExhibitorResponse } from './models/exhibitors';
import { Registration } from '../registration/models/registration';
import { DownloadResponse } from '../shared/models/download-response';

@Injectable({
    providedIn: 'root'
})
export class AgTeachersService {

    private http = inject(HttpClient);

    public getExhibitors(params: any): Observable<ExhibitorResponse> {
        var url = `${environment.serviceHostName}/exhibitors?orgId=${params.orgId}&page=${params.pageNo}&pageSize=${params.pageSize}`;
        return this.http.get<ExhibitorResponse>(url);
    }

    public getExhibitorsByChapter(params: any): Observable<ExhibitorResponse> {
        var url = `${environment.serviceHostName}/exhibitors/chapter?orgId=${params.orgId}&chapterId=${params.chapterId}&page=${params.pageNo}&pageSize=${params.pageSize}&orderBy=${params.orderBy}`;
        return this.http.get<ExhibitorResponse>(url);
    }

    public getExhibitor(orgId: number, id: number): Observable<Registration> {
        var url = `${environment.serviceHostName}/exhibitors/${id}?orgId=${orgId}`;
        return this.http.get<Registration>(url);
    }

    public updateExhibitor(exhibitor: Registration): Observable<Registration> {
        var url = `${environment.serviceHostName}/exhibitors`;
        return this.http.put<Registration>(url, exhibitor);
    }

    public approveExhibitor(params: any): Observable<Registration> {
        var url = `${environment.serviceHostName}/exhibitors/Approve/${params.id}?chapterId=${params.chapterId}&comments=${params.comments}`;
        return this.http.post<Registration>(url, params);
    }

    public approveExhibitorWithConditions(params: any): Observable<Registration> {
        var url = `${environment.serviceHostName}/exhibitors/ApproveWithConditions/${params.id}?chapterId=${params.chapterId}&comments=${params.comments}`;
        return this.http.post<Registration>(url, params);
    }

    public rejectExhibitor(params: any): Observable<Registration> {
        var url = `${environment.serviceHostName}/exhibitors/Reject/${params.id}?chapterId=${params.chapterId}&comments=${params.comments}`;
        return this.http.post<Registration>(url, params);
    }

    public ReportedIssueExhibitor(params: any): Observable<Registration> {
        var url = `${environment.serviceHostName}/exhibitors/ReportIssue/${params.id}?chapterId=${params.chapterId}&comments=${params.comments}`;
        return this.http.post<Registration>(url, params);
    }

    public validateCountyAgent(params: any): Observable<any> {
        var url = `${environment.serviceHostName}/CountyAgent?UserName=${params.UserName}&Password=${params.Password}&abbreviation=${params.Abbreviation}`;
        return this.http.get<any>(url);
    }

    public validateCountyAdminByAccessCode(params: any): Observable<any> {
        var url = `${environment.serviceHostName}/CountyAgent/Login?ChapterId=${params.ChapterId}&AccessCode=${params.AccessCode}&abbreviation=${params.Abbreviation}`;
        return this.http.get<any>(url);
    }

    public liveStockSummary(params: any): Observable<LiveStockSummary> {
        var url = `${environment.serviceHostName}/DivisionClass/livestocksummary?ExhibitorId=${params.id}&orgId=${params.orgId}`;
        return this.http.get<LiveStockSummary>(url);
    }

    public async export(orgId: number, chapterId: number): Promise<DownloadResponse> {
        var url = `${environment.serviceHostName}/exhibitors/export?orgId=${orgId}&chapterId=${chapterId}`;

        return firstValueFrom(this.http.get<any>(url, { observe: 'response', responseType: 'blob' as 'json' })
            .pipe(
                map((response) => {
                    var fileName = response.headers.get('x-file-name');

                    if (fileName) {
                        let data: DownloadResponse = {
                            file: response.body,
                            fileName: fileName
                        };

                        return Promise.resolve(data);
                    } else {
                        return Promise.reject();
                    }
                })
            ));
    }

    public resendApprovedEmail(orgId: number, exhibitorId: number): Observable<void> {
        var url = `${environment.serviceHostName}/email/approved?orgId=${orgId}&id=${exhibitorId}`;
        return this.http.post<void>(url, {});
    }

    public resendRejectedEmail(orgId: number, exhibitorId: number): Observable<void> {
        var url = `${environment.serviceHostName}/email/rejected?orgId=${orgId}&id=${exhibitorId}`;
        return this.http.post<void>(url, {});
    }

    public resendReportedIssueEmail(orgId: number, exhibitorId: number): Observable<void> {
        var url = `${environment.serviceHostName}/email/reportIssue?orgId=${orgId}&id=${exhibitorId}`;
        return this.http.post<void>(url, {});
    }

    public resendSubmittedEmail(orgId: number, exhibitorId: number): Observable<void> {
        var url = `${environment.serviceHostName}/email/confirmation?orgId=${orgId}&id=${exhibitorId}`;
        return this.http.post<void>(url, {});
    }

}
