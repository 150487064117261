export enum Steps {
    ExhibitorInfo = 1,
    Livestocks = 2,
    Disclosures = 3,
    Payment = 4,
    Confirmation = 5
}

/*const StepsDetails = {
    ExhibitorInfo: { value: 1, name: 'Active' },
    Livestocks: { value: 2, name: 'Inactive' },
    AdditionalInfo: { value: 3, name: 'Pending' },
    Payment: { value: 3, name: 'Pending' },
    Confirmation: { value: 3, name: 'Pending' }
} as const;*/