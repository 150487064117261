<section class="h-100 d-flex flex-column">
    <div class="scrollable ps-0">
        <div class="section-title">Personal Information</div>
        <div class="section-box">
            <div class="row">
                <div class="col-md">
                    <div class="d-flex flex-column gap-1">
                        <span class="label">First Name</span>
                        <span class="fs-6 value">{{exhibitor.firstName | titlecase }}</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="d-flex flex-column gap-1">
                        <span class="label">Last Name</span>
                        <span class="fs-6 value">{{exhibitor.lastName | titlecase }}</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="d-flex flex-column gap-1">
                        <span class="label">Date of Birth</span>
                        <span class="fs-6 value">{{exhibitor.exhibitorDOB | date:'MM/dd/yyyy'}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="d-flex flex-column gap-1">
                        <span class="label">Mailing Address</span>
                        <span class="fs-6 value">{{exhibitor.address}}</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="d-flex flex-column gap-1">
                        <span class="label">City</span>
                        <span class="fs-6 value">{{exhibitor.city}}</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="d-flex flex-column gap-1">
                        <span class="label">State</span>
                        <span class="fs-6 value">{{exhibitor.state}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="d-flex flex-column gap-1">
                        <span class="label">Zip Code</span>
                        <span class="fs-6 value">{{exhibitor.zipCode}}</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="d-flex flex-column gap-1">
                        <span class="label">Email</span>
                        <span class="fs-6 value">{{exhibitor.email}}</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="d-flex flex-column gap-1">
                        <span class="label">Phone</span>
                        <span class="fs-6 value">{{exhibitor.phone}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-title mt-3">Quality Counts</div>
        <div class="section-box">
            <div class="row">
                <div class="col-md">
                    <div class="d-flex flex-column gap-1">
                        <span class="label">Quality Count Number</span>
                        <span *ngIf="exhibitor.qualityCounts && exhibitor.qualityCounts.qcNumber"
                            class="fs-6 value">{{exhibitor.qualityCounts.qcNumber}}</span>
                        <span *ngIf="!exhibitor.qualityCounts || !exhibitor.qualityCounts.qcNumber"
                            class="fs-6 value">N/A</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="d-flex flex-column gap-1"><span class="label">Grade</span>
                        <span *ngIf="exhibitor.qualityCounts && exhibitor.qualityCounts.currentGrade"
                            class="fs-6 value">{{exhibitor.qualityCounts.currentGrade}}</span>
                        <span *ngIf="!exhibitor.qualityCounts || !exhibitor.qualityCounts.currentGrade"
                            class="fs-6 value">N/A</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="d-flex flex-column gap-1"><span class="label">Level</span>
                        <span *ngIf="exhibitor.qualityCounts && exhibitor.qualityCounts.level"
                            class="fs-6 value">{{exhibitor.qualityCounts.level}}</span>
                        <span *ngIf="!exhibitor.qualityCounts || !exhibitor.qualityCounts.level"
                            class="fs-6 value">N/A</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-title mt-3">Entries</div>
        <div class="section-box">
            <div *ngFor="let dept of liveStockSummary.liveStocks; let i = index">
                <div class="dept-name">{{dept.department}}</div>
                <table class="table p-0 pt-3 pb-3">
                    <thead>
                        <tr>
                            <th *ngIf="dept.department?.toLocaleLowerCase() == 'ag mechanics'" scope="col" width="35%">
                                Division</th>
                            <th scope="col" width="">Class</th>
                            <th *ngIf="dept.department?.toLocaleLowerCase() != 'ag mechanics'" scope="col" width="35%">
                                Breed/Heifer</th>
                            <th scope="col" class="text-end" width="10%">Price</th>
                            <th scope="col" class="text-end" width="10%">Quantity</th>
                            <th scope="col" class="text-end" width="10%">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (livestock of dept.liveStocks; track $index) {
                        <tr>
                            <td *ngIf="dept.department?.toLocaleLowerCase()  == 'ag mechanics'">
                                <div>
                                    <span>
                                        {{livestock.division ?? '--'}}
                                    </span>
                                    <span *ngIf="livestock.projectDesc" (click)="showOverlayPanel($event, $index)">
                                        <i class="pi pi-info-circle"></i>
                                    </span>
                                </div>
                                <p-overlayPanel>
                                    <ng-template pTemplate="content">
                                        <p class="title">Project Description</p>
                                        <p>{{livestock.projectDesc}}</p>
                                    </ng-template>
                                </p-overlayPanel>
                            </td>
                            <td>{{livestock.class}}
                                <div *ngIf="livestock?.class?.toLowerCase() == 'breeding heifers'" class="show-hide-heifer-details"
                                    (click)="livestock.expanded = !livestock.expanded">
                                    {{livestock.expanded ? 'Hide Details' : 'Show Details'}}
                                </div>
                            </td>
                            <td *ngIf="dept.department?.toLowerCase() != 'ag mechanics'">
                                <!--<div *ngIf="livestock.heiferName">{{livestock.heiferName}}</div>
                                <div *ngIf="!livestock.heiferName">
                                    {{livestock.subClass ?? '--'}}
                                    <span *ngIf="livestock.sex">-&nbsp;{{livestock.sex}}</span>
                                </div>-->
                                {{getSubClassName(livestock)}}
                            </td>
                            <td class="text-end">{{livestock.priceEach| currency:'USD'}}</td>
                            <td class="text-end">{{livestock.count}}</td>
                            <td class="text-end">{{livestock.calculatedPrice | currency:'USD'}}</td>
                        </tr>
                        <tr *ngIf="livestock?.class?.toLowerCase() == 'breeding heifers'">
                            <td *ngIf="livestock.heiferId" class="heifer-details" colspan="5"
                                [ngClass]="{'open': livestock.expanded, 'close': !livestock.expanded}">
                                <div class="row">
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Heifer Registry #</span>
                                        <span class="value">{{livestock.heiferRegistryNo}}</span>
                                    </div>
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Date of Birth</span>
                                        <span class="value">{{livestock.dateOfBirth}}</span>
                                    </div>
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Breed</span>
                                        <span class="value">{{livestock.breed}}</span>
                                    </div>
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Breed Name</span>
                                        <span class="value">{{livestock.breedName}}</span>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Chapter</span>
                                        <span class="value">{{livestock.countyName}}</span>
                                    </div>
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Billing County</span>
                                        <span class="value">{{livestock.billingCounty}}</span>
                                    </div>
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Sire Name</span>
                                        <span class="value">{{livestock.sireName}}</span>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="!livestock.heiferId|| livestock.heiferId == undefined" class="heifer-details" colspan="5"
                                [ngClass]="{'open': livestock.expanded, 'close': !livestock.expanded}">
                                <div class="row">
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Validation Tag Number</span>
                                        <span class="value">{{livestock.validationTagNumber}}</span>
                                    </div>
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Registration Number</span>
                                        <span class="value">{{livestock.registrationNumber}}</span>
                                    </div>
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Breed</span>
                                        <span class="value">{{livestock.breed}}</span>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Animal Name</span>
                                        <span class="value">{{livestock.animalName}}</span>
                                    </div>
                                    <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                        <span class="label">Birth Date</span>
                                        <span class="value">{{livestock.birthDate | date: 'MM/dd/yyyy'}}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div class="total text-end">Total : {{liveStockSummary.totalAmount | currency:'USD'}}</div>
        </div>
    </div>
    <div class="mt-2">
        <div class="row">
            <div class="col-md">
                <div class="d-flex flex-column">
                    <label for="comments" class="fw-bold mb-1">Comments</label>
                    <textarea id="comments" pInputText [disabled]="isValidated" [(ngModel)]="exhibitor.approvalComments"
                        rows="2"></textarea>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-2">
                <p-button [outlined]="true" [rounded]="true" label="Back" icon="pi pi-angle-left" (onClick)="back()" />
            </div>
            <div class="col-md-10">
                <div class="d-flex justify-content-end gap-2">
                    <p-button [outlined]="true" [rounded]="true" [disabled]="isValidated" severity="danger"
                        icon="pi pi-times-circle" label="Reject" (onClick)="rejectExhibitor()"></p-button>
                    <p-button [outlined]="true" [rounded]="true" [disabled]="isValidated" severity="warning"
                        icon="pi pi-exclamation-triangle" label="Report Issue"
                        (onClick)="ReportedIssueExhibitor()"></p-button>
                    <p-button [rounded]="true" [disabled]="isValidated" icon="pi pi-check" label="Approve"
                        (onClick)="approveExhibitor()"></p-button>
                </div>
            </div>
        </div>
    </div>
</section>