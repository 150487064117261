import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RegistrationService } from './registration/registration.service';
import { NotificationService } from './shared/service/notification.service';
import { DataService } from './shared/service/dataService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  private monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  registrationDates: string = '';
  eventDates: string = '';
  isOrgConfigsLoaded: boolean = false;
  isRegistrationStarted: boolean = true;

  constructor(
    private registrationService: RegistrationService,
    private notificationService: NotificationService,
    private dataService: DataService,
    private titleService: Title,
    private renderer: Renderer2) { }

  ngOnInit(): void {
    this.setFavicon('assets/hill-district.png');
    this.registrationService.getOrgConfigsByAbbreviation('hill')
      .then(response => {
        this.dataService.orgConfigs = response;
        this.titleService.setTitle(response.events.eventName);
        this.registrationDates = this.formatEventDates(response.events.registrationStart, response.events.registrationEnd);
        this.eventDates = this.formatEventDates(response.events.scheduleStart, response.events.scheduleEnd);
        this.isOrgConfigsLoaded = true;

        const date = new Date();
        const startDate = new Date(response.events.registrationStart);
        const endDate = new Date(response.events.registrationEnd);

        if (date >= startDate && date <= endDate) {
          this.isRegistrationStarted = true;
        }
      })
      .catch(err => {
        console.error(err);
        this.notificationService.error();
      });
  }

  private setFavicon(url: string) {
    const link: HTMLLinkElement = this.renderer.selectRootElement('link[rel="icon"]', true);
    if (link) {
      link.href = url;
    }
  }

  formatEventDates(startDateStr: string, endDateStr: string): string {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const startMonth = this.getMonthName(startDate.getMonth());
    const year = endDate.getFullYear(); // Assuming the year is the same for both dates

    const startSuffix = this.getDaySuffix(startDay);
    const endSuffix = this.getDaySuffix(endDay);

    return `${startMonth} ${startDay}<sup>${startSuffix}</sup> - ${endDay}<sup>${endSuffix}</sup>, ${year}`;
  }

  private getMonthName(month: number): string {
    return this.monthNames[month];
  }

  private getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return 'th'; // Special case for teens
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

}