import { Injectable } from '@angular/core';
import { OrgConfigs } from '../models/org-configs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _orgConfigs!: OrgConfigs;

  get orgConfigs(): OrgConfigs {
    return this._orgConfigs;
  }

  set orgConfigs(orgConfigs: OrgConfigs) {
    this._orgConfigs = orgConfigs;
  }

}
