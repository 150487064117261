import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgTeachersService } from '../ag-teachers.service';
import { Router } from '@angular/router';
import { Chapter } from '../../registration/models/chapter';
import { RegistrationService } from '../../registration/registration.service';
import { NotificationService } from '../../shared/service/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private registrationService: RegistrationService,
    private notificationService: NotificationService,
    private agTeachersServices: AgTeachersService,
    private route: Router
  ) { }

  loginForm!: FormGroup;
  isSubmiting: boolean = false;
  errorMessage: string = "";
  chapters: Chapter[] = [];

  ngOnInit(): void {
    this.loginForm = this.fb.group(
      {
        //userName: ['', [Validators.required, Validators.email]],
        //password: ['', [Validators.required]]
        chapter: ['', [Validators.required]],
        accessCode: ['', [Validators.required]]
      }
    );

    this.registrationService.getChapters().subscribe({
      next: response => {
        this.chapters = response.sort((a, b) => a.chapter.localeCompare(b.chapter));;
      },
      error: err => {
        console.error(err);
        this.notificationService.error();
      }
    });
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onLogin();
      event.preventDefault();
    }
  }

  onLogin() {
    this.loginForm.markAllAsTouched();
    this.loginForm.updateValueAndValidity();

    if (this.loginForm.valid) {
      this.isSubmiting = true;
      const params = {
        //UserName: this.loginForm.get('userName')?.value,
        //Password: this.loginForm.get('password')?.value,
        ChapterId: this.loginForm.get('chapter')?.value,
        AccessCode: this.loginForm.get('accessCode')?.value,
        Abbreviation: "Hill"
      };

      this.agTeachersServices.validateCountyAdminByAccessCode(params).subscribe({
        next: response => {
          sessionStorage.setItem("orgId", response.orgId);
          sessionStorage.setItem("chapterId", response.chapterId);
          sessionStorage.setItem("chapterName", this.chapters.find(c => c.id == response.chapterId)?.chapter ?? '');
          this.isSubmiting = false;

          this.route.navigateByUrl("ag-teachers/entries");
        },
        error: err => {
          this.errorMessage = "Invalid Credenitals!";
          this.isSubmiting = false;
        }
      });
    }
  }

  showErrorMessage(formControlName: string): boolean {
    const fc = this.loginForm.controls[formControlName];
    return (fc.touched || fc.dirty) && fc.invalid;
  }

}
