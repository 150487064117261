<section *ngIf="registrationResponse" class="mb-5">
    <div class="reg-success">
        <div class="row w-100">
            <div class="col"></div>
            <div class="col d-flex justify-content-center">
                <i class="pi pi-check"></i>
            </div>
            <div class="col d-flex align-items-center justify-content-end">
                <i class="pi pi-print" (click)="onPrintConfirmation()"></i>
                <i class="pi pi-download ms-2" (click)="onDownloadConfirmation()"></i>
            </div>
        </div>
        <div>Your entry to the 2025 Hill District Grandstand Show has been submitted.</div>
        <div>Your entry has been sent to your Ag Teacher/County Agent for approval. You will receive an
            email confirmation that your entry has been submitted and another when your entry has been approved by your
            Ag Teacher/County Agent.</div>
        <!--<div>Transaction ID: {{paymentResponse?.transId}}</div>-->
    </div>
</section>
<section>
    <!--<div class="exhibitor-name">{{registration.fullName | titlecase}}</div>-->
    <div class="summary-box">
        <div class="section-title">Entry Information</div>
        <div *ngFor="let department of selectedLivestocks">
            <div class="dept-name">{{department[0]}}</div>
            <table class="s-table">
                <thead>
                    <tr>
                        <th *ngIf="department[0].toLowerCase() == 'ag mechanics'" scope="col" width="35%"
                            data-label="Division">Division</th>
                        <th scope="col" width="" data-label="Class">Class</th>
                        <th *ngIf="department[0].toLowerCase() != 'ag mechanics'" scope="col" width="35%"
                            data-label="Breed/Heifer">Breed/Heifer
                        </th>
                        <th scope="col" class="text-end" width="10%" data-label="Price">Price</th>
                        <th scope="col" class="text-end" width="10%" data-label="Quantity">Quantity</th>
                        <th scope="col" class="text-end" width="10%" data-label="Total">Total</th>
                    </tr>
                </thead>
                <tbody>
                    @for (livestock of department[1]; track $index) {
                    <tr scope="row">
                        <td *ngIf="department[0].toLowerCase() == 'ag mechanics'" scope="col" data-label="Division">
                            <div>
                                <span>
                                    {{livestock.division ?? '--'}}
                                </span>
                                <span *ngIf="livestock.projectDesc">
                                    <i class="pi pi-info-circle" [pTooltip]="livestock.projectDesc"
                                        tooltipPosition="top"></i>
                                </span>
                            </div>
                        </td>
                        <td scope="col" data-label="Class">
                            <div class="class-name">
                                <div>{{livestock.class}}</div>
                                <div *ngIf="livestock.qualityCount" class="show-hide-heifer-details"
                                    (click)="livestock.expanded = !livestock.expanded">
                                    {{livestock.expanded ? 'Hide Details' : 'Show Details'}}
                                </div>
                            </div>
                        </td>
                        <td *ngIf="department[0].toLowerCase() != 'ag mechanics'" scope="col" data-label="Breed/Heifer">
                            <!--<div *ngIf="livestock.qualityCount">{{livestock.heiferName ?? '-'}}</div>
                            <div *ngIf="!livestock.qualityCount">
                                {{livestock.subClass ?? '-'}}
                                <span *ngIf="livestock.sex">-&nbsp;{{livestock.sex}}</span>
                            </div>-->
                            {{getSubClassName(livestock)}}
                        </td>
                        <td scope="col" class="text-end" data-label="Price">{{livestock.priceEach | currency:'USD'}}
                        </td>
                        <td scope="col" class="text-end" data-label="Quantity">{{livestock.count}}</td>
                        <td scope="col" class="text-end" data-label="Total">{{livestock.totalPrice | currency:'USD'}}
                        </td>
                    </tr>
                    <tr *ngIf="livestock.qualityCount" scope="row">
                        <td *ngIf="livestock.heiferId != undefined && livestock.heiferId != -1" class="heifer-details"
                            colspan="5" [ngClass]="{'open': livestock.expanded, 'close': !livestock.expanded}">
                            <div class="row">
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Heifer Registry #</span>
                                    <span class="value">{{livestock.qualityCount.heiferRegistryNo}}</span>
                                </div>
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Date of Birth</span>
                                    <span class="value">{{livestock.qualityCount.dateOfBirth}}</span>
                                </div>
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Breed</span>
                                    <span class="value">{{livestock.breed}}</span>
                                </div>
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Breed Name</span>
                                    <span class="value">{{livestock.qualityCount.breedName}}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Chapter</span>
                                    <span class="value">{{livestock.qualityCount.countyName}}</span>
                                </div>
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Billing County</span>
                                    <span class="value">{{livestock.qualityCount.billingCounty}}</span>
                                </div>
                                <div class="col-md-9 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Sire Name</span>
                                    <span class="value">{{livestock.qualityCount.sireName}}</span>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="livestock.heiferId == -1 || livestock.heiferId == undefined" class="heifer-details"
                            colspan="5" [ngClass]="{'open': livestock.expanded, 'close': !livestock.expanded}">
                            <div class="row">
                                <div class="col-md d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Validation Tag Number</span>
                                    <span class="value">{{livestock.validationTagNumber}}</span>
                                </div>
                                <div class="col-md d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Registration Number</span>
                                    <span class="value">{{livestock.registrationNumber}}</span>
                                </div>
                                <div class="col-md d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Breed</span>
                                    <span class="value">{{livestock.breed}}</span>
                                </div>
                                <!--</div>
                            <div class="row mt-2">-->
                                <div class="col-md d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Animal Name</span>
                                    <span class="value">{{livestock.animalName}}</span>
                                </div>
                                <div class="col-md d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Birth Date</span>
                                    <span class="value">{{livestock.birthDate | date: 'MM/dd/yyyy'}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
        <div class="total text-end">Total : {{registration.totalPayable| currency:'USD'}}</div>
    </div>
    <div *ngIf="!registrationResponse" class="mt-3 payment">
        <!--<div class="border rounded col-md p-4 d-flex justify-content-center">-->
        <div>
            <form [formGroup]="paymentForm">
                <div class="row">
                    <div class="col payment-header">Secure Payment</div>
                </div>
                <div class="row">
                    <div class="col">
                        <img class="card-type-logo" src="assets/visa-logo.png" alt="visa" />
                        <img class="card-type-logo" src="assets/mastercard-logo.png" alt="mastercard" />
                        <img class="card-type-logo" src="assets/amex-logo.png" alt="amex" />
                        <img class="card-type-logo" src="assets/discover-logo.jpg" alt="discover" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <p-floatLabel>
                            <input pInputText formControlName="nameOnCard" type="text" class="w-100"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('nameOnCard') }" />
                            <label>Name on Card</label>
                        </p-floatLabel>
                        <span *ngIf="showErrorMessage('nameOnCard')" class="error-message">Please enter Name on
                            Card</span>
                    </div>
                    <div class="col-md">
                        <p-floatLabel>
                            <input pInputText formControlName="cardNumber" type="text" class="w-100"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('cardNumber') }" />
                            <label>Card Number</label>
                        </p-floatLabel>
                        <span *ngIf="showErrorMessage('cardNumber')" class="error-message">Please enter Card
                            Number</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <p-floatLabel>
                            <p-inputMask formControlName="expirationDate" type="text" mask="99/99" placeholder="MM/YY"
                                styleClass="w-100"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('expirationDate') }" />
                            <label>Expiration Date</label>
                        </p-floatLabel>
                        <span *ngIf="showErrorMessage('expirationDate')" class="error-message">Please enter Expiration
                            Date</span>
                    </div>
                    <div class="col-md">
                        <p-floatLabel>
                            <input pInputText formControlName="cardCode" type="password" pKeyFilter="int" minlength="3"
                                maxlength="4" class="w-100"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('cardCode') }" />
                            <label>Security Code</label>
                        </p-floatLabel>
                        <span *ngIf="showErrorMessage('cardCode')" class="error-message">Please enter Security
                            Code</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <p-floatLabel>
                            <input pInputText formControlName="billingZipCode" pKeyFilter="int" minlength="5"
                                maxlength="5" type="text" class="w-100"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('billingZipCode') }" />
                            <label>Billing Zip Code</label>
                        </p-floatLabel>
                        <span *ngIf="showErrorMessage('billingZipCode')" class="error-message">Please enter Billing
                            Zip Code</span>
                    </div>
                    <div class="col-md"></div>
                </div>
                <div class="row">
                    <div class="col-md d-flex justify-content-end gap-2">
                        <p-button label="Back" [outlined]="true" [rounded]="true" (onClick)="onPreviousClick()" />
                        <p-button [disabled]="processingPayment" (onClick)="onPay()" [rounded]="true">
                            <ng-template pTemplate="content">
                                <div *ngIf="processingPayment" class="w-100 text-center"><i
                                        class="pi pi-spin pi-spinner"></i></div>
                                <div *ngIf="!processingPayment" class="w-100 text-center">Pay</div>
                            </ng-template>
                        </p-button>
                    </div>
                </div>
            </form>
        </div>
        <!--</div>-->
    </div>
</section>