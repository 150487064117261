import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { EntriesComponent } from './entries/entries.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { EditEntryComponent } from './entries/edit-entry/edit-entry.component';
import { PaginatorModule } from 'primeng/paginator';
import { AuthGuard } from './auth.guard';

@NgModule({
  declarations: [
    LoginComponent,
    EntriesComponent,
    EditEntryComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    TableModule,
    PaginatorModule,
    RouterModule.forRoot([
      { path: 'ag-teachers', redirectTo: 'ag-teachers/login', pathMatch: 'full' },
      { path: 'ag-teachers/login', component: LoginComponent },
      { path: 'ag-teachers/entries', component: EntriesComponent, canActivate: [AuthGuard] },
      { path: 'ag-teachers/:action/:id', component: EditEntryComponent, canActivate: [AuthGuard] },
    ])
  ]
})
export class AgTeachersModule { }
