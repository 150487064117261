import { PaymentRequest } from "./payment";
import { QualityCounts, QualityCountsHeifers } from "./quality-counts";
import { SigningDetails } from "./signing-details";

export class RegistrationRequest {
    exhibitorsSellers!: Registration;
    authorizationDetails!: PaymentRequest;
}

export class Registration {
    orgId?: number;
    id?: number;
    eventId?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    exhibitorDOB?: Date;
    exhibitorDOBStr?: string;
    email?: string;
    phone?: string;
    address?: string;
    state?: string;
    city?: string;
    zipCode?: string;
    county?: string;
    chapter?: string;
    //qcNumber?: number;
    //currentGrade?: number;
    ffaCounty?: string;
    //level?: string;
    fourHCounty?: string;
    status?: string;
    totalPayable?: number;
    //transactionId?: string;
    //cfcContactOptIn?: boolean;
    liveStocks?: Array<Livestock>;
    //signingDetails?: SigningDetails;
    qualityCounts?: QualityCounts;
    waiverComplete?: boolean;
    ageDiscloserRequired?: boolean;
    ageDiscloserAccepted?: boolean;
    approvalComments?: string;

    constructor(data?: Partial<Registration>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

export class Livestock {
    //id!: number;
    exhibitor_Id?: number;
    department!: string;
    division?: string;
    divisionId?: string;
    class!: string;
    classId!: number;
    subClassId?: number;
    subClass?: string;
    sex?: string;
    heiferId?: number;
    heiferName?: string;
    qualityCount?: QualityCountsHeifers;
    count!: number;
    priceEach!: number;
    totalPrice!: number;
    calculatedPrice?: number;
    expanded!: boolean;
    projectDesc?: string;
    validationTagNumber?: string;
    animalName?: string;
    breed?: string;
    birthDate?: string;
    registrationNumber?: string;

    constructor(data?: Partial<Livestock>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

export class RegistrationResponse {
    exhibitor!: Registration;
    paymentResponse!: PaymentResponse;
}

export class PaymentResponse {
    id!: number;
    status!: boolean;
    transId!: string;
    response!: PaymentResponsePayload;
}

export class PaymentResponsePayload {
    responseCode!: string;
    messageCode!: string;
    messageDescription!: string;
    messageText!: string;
    authCode!: string;
    errorCode!: string;
    errorText!: string;
}