<div class="footer mb-2 mt-2">
    <div class="client-name">Hill District Grandstand Show</div>
    <div class="d-md-flex gap-2 align-items-center justify-content-center">
        <span>PO Box 113 Fredericksburg TX 78624</span>
        <span class="d-flex justify-content-center align-items-center gap-1 link">
            <i class="pi pi-globe"></i>
            <a target="_blank" rel="noopener"
                href="https://www.hilldistrictgrandstand.com/">www.hilldistrictgrandstand.com</a></span>
        <span class="d-flex justify-content-center align-items-center gap-1 link">
            <i class="pi pi-envelope"></i>
            <a href="mailto:thegrandstand21&#64;gmail.com">thegrandstand21&#64;gmail.com</a>
        </span>
    </div>
</div>