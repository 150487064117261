<section *ngIf="!departments" class="d-flex justify-content-center">
    <p-progressSpinner />
</section>
<p-dialog header="Heifer Details" [(visible)]="showDialog" [modal]="true" [closable]="true" [draggable]="false"
    [style]="{ width: '50%' }">
    <div class="py-2">
        <form novalidate [formGroup]="heiferForm">
            <div class="row">
                <div class="col-md-4">
                    <label>Validation Tag Number</label>
                </div>
                <div class="col-md-8">
                    <p-floatLabel>
                        <input pInputText formControlName="validationTagNumber" type="text" class="w-100" minlength="6"
                            maxlength="6" (keydown)="onKeyDown($event)"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showHeiferErrorMessage('validationTagNumber') }"
                            placeholder="Enter Validation Tag Number" />
                    </p-floatLabel>
                    <span
                        *ngIf="showHeiferErrorMessage('validationTagNumber') && heiferForm.controls['validationTagNumber'].errors && heiferForm.controls['validationTagNumber'].errors['required']"
                        class="error-message">
                        Please enter Validation Tag Number
                    </span>
                    <span
                        *ngIf="showHeiferErrorMessage('validationTagNumber') && heiferForm.controls['validationTagNumber'].errors && heiferForm.controls['validationTagNumber'].errors['minlength']"
                        class="error-message">
                        Please enter 6 digit Validation Tag Number
                    </span>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                    <label>Animal Name</label>
                </div>
                <div class="col-md-8">
                    <p-floatLabel>
                        <input pInputText formControlName="animalName" type="text" class="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showHeiferErrorMessage('animalName') }"
                            placeholder="Enter Animal Name" />
                    </p-floatLabel>
                    <span *ngIf="showHeiferErrorMessage('animalName')" class="error-message">
                        Please enter Animal Name
                    </span>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                    <label>Breed</label>
                </div>
                <div class="col-md-8">
                    <p-floatLabel>
                        <p-dropdown formControlName="breed" [options]="heiferBreeds" optionLabel="name"
                            optionValue="value" styleClass="w-100" class="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showHeiferErrorMessage('breed') }"
                            placeholder="Select breed" appendTo="body" />
                    </p-floatLabel>
                    <span *ngIf="showHeiferErrorMessage('breed')" class="error-message">Please select Breed</span>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                    <label>Birth Date</label>
                </div>
                <div class="col-md-8">
                    <p-floatLabel>
                        <p-calendar formControlName="birthDate" placeholder="MM/DD/YYYY" [iconDisplay]="'input'"
                            [showIcon]="true" [touchUI]="false" [minDate]="minHeiferBirthDate"
                            [maxDate]="maxHeiferBirthDate" [defaultDate]="maxHeiferBirthDate" maxlength="10"
                            styleClass="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showHeiferErrorMessage('birthDate') }"
                            (onInput)="onDateInput($event)" />
                    </p-floatLabel>
                    <span *ngIf="showHeiferErrorMessage('birthDate')" class="error-message">Please select Birth
                        Date</span>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                    <label>Registration Number</label>
                </div>
                <div class="col-md-8">
                    <p-floatLabel>
                        <input pInputText formControlName="registrationNumber" type="text" class="w-100"
                            (keydown)="onKeyDown($event)"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showHeiferErrorMessage('registrationNumber') }"
                            placeholder="Enter Registration Number">
                    </p-floatLabel>
                    <span *ngIf="showHeiferErrorMessage('registrationNumber')" class="error-message">
                        Please enter Registration Number
                    </span>
                </div>
            </div>
            <div class="text-end mt-4">
                <p-button [outlined]="true" [rounded]="true" tabindex="-1" label="Close"
                    (onClick)="showDialog = false" />
                <p-button [rounded]="true" tabindex="-1" label="Add" class="ms-2" (onClick)="addHeiferDetails()" />
            </div>
        </form>
    </div>
</p-dialog>
<section *ngIf="departments">
    <div #stepper class="d-md-flex gap-2">
        <div *ngFor="let department of departments; let i = index" class="col-md tab mb-1 mb-md-0"
            [ngClass]="{ 'active': department.department == selectedDept }"
            (click)="onDeptChange(department.department)">
            {{department.department}}
        </div>
    </div>
    <div class="mt-3">
        <div class="section-title">Add Entry</div>
        <div class="p-2">
            <div>
                <form novalidate [formGroup]="livestockForm">
                    <div *ngIf="selectedDept.toLowerCase() == 'ag mechanics'" class="row mb-2">
                        <div class="col-md-1 mb-1 mb-md-0 d-flex align-items-center">
                            <label>Division</label>
                        </div>
                        <div class="col-md-5 mb-1 mb-md-0">
                            <p-dropdown formControlName="division" [options]="divisions" optionLabel="division"
                                optionValue="divisionId" [filter]="false" (onChange)="onDivisionChange()"
                                styleClass="w-100" class="w-100"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('division') }"
                                placeholder="Select division" appendTo="body" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-1 mb-1 mb-md-0 d-flex align-items-center">
                            <label>Class</label>
                        </div>
                        <div class="col-md-5 mb-1 mb-md-0">
                            <p-dropdown formControlName="class" [options]="classes" optionLabel="classDescription"
                                optionValue="classId" [filter]="false" (onChange)="onClassChange()" styleClass="w-100"
                                class="w-100" [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('class') }"
                                placeholder="Select class" appendTo="body" />
                        </div>
                    </div>
                    <div *ngIf="selectedDept.toLowerCase() != 'ag mechanics' && subClasses && subClasses.length > 0"
                        class="row mb-2">
                        <div class="col-md-1 mb-1 mb-md-0 d-flex align-items-center">
                            <label>Breed</label>
                        </div>
                        <div class="col-md-5 mb-1 mb-md-0">
                            <p-dropdown formControlName="subClass" [options]="subClasses" optionLabel="name"
                                optionValue="id" [filter]="false" styleClass="w-100" class="w-100"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('subClass') }"
                                placeholder="Select breed" appendTo="body" />
                        </div>
                    </div>
                    <div *ngIf="showTurkeySex" class="row mb-2">
                        <div class="col-md-1 mb-1 mb-md-0 d-flex align-items-center">
                            <label>Sex</label>
                        </div>
                        <div class="col-md-5 mb-1 mb-md-0">
                            <p-radioButton formControlName="sex" value="Hen" inputId="Hen" label="Hen"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('sex') }" />
                            <p-radioButton formControlName="sex" value="Tom" inputId="Tom" label="Tom" class="ms-2"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('sex') }" />
                        </div>
                    </div>
                    <div *ngIf="showSheepSex" class="row mb-2">
                        <div class="col-md-1 mb-1 mb-md-0 d-flex align-items-center">
                            <label>Sex</label>
                        </div>
                        <div class="col-md-5 mb-1 mb-md-0">
                            <p-radioButton formControlName="sex" value="Ram" inputId="Ram" label="Ram"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('sex') }" />
                            <p-radioButton formControlName="sex" value="Ewe" inputId="Ewe" label="Ewe" class="ms-2"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('sex') }" />
                        </div>
                    </div>
                    <div *ngIf="selectedDept.toLowerCase() != 'ag mechanics' && qualityCountHeifers && qualityCountHeifers.length > 0"
                        class="row mb-2">
                        <div class="col-md-1 mb-1 mb-md-0 d-flex align-items-center">
                            <label>Heifer</label>
                        </div>
                        <div class="col-md-5 mb-1 mb-md-0 d-flex justify-content-end">
                            <p-dropdown formControlName="subClass" [options]="qualityCountHeifers"
                                optionLabel="displayName" optionValue="id" [filter]="false" styleClass="w-100"
                                class="w-100" [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('subClass') }"
                                (onChange)="onHeiferChange($event)" placeholder="Select heifer" appendTo="body" />
                        </div>
                    </div>
                    <div *ngIf="selectedDept.toLowerCase() != 'ag mechanics' && qualityCountHeifers && qualityCountHeifers.length > 0 && showHeiferBreeds"
                        class="row mb-2">
                        <div class="col-md-1 mb-1 mb-md-0 d-flex align-items-center">
                            <label>Breed</label>
                        </div>
                        <div class="col-md-5 mb-1 mb-md-0 d-flex justify-content-end">
                            <p-dropdown formControlName="breed" [options]="heiferBreeds" optionLabel="name"
                                optionValue="value" styleClass="w-100" class="w-100"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('breed') }"
                                placeholder="Select breed" appendTo="body" />
                        </div>
                    </div>
                    <div *ngIf="selectedDept.toLowerCase() == 'ag mechanics'" class="row">
                        <div class="col-md-1 d-flex align-items-center mb-1 mb-md-0">
                            <label>Description</label>
                        </div>
                        <div class="col-md-5 mb-1 mb-md-0">
                            <textarea pInputText formControlName="projectDesc" rows="2" class="w-100"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('projectDesc') }"
                                placeholder="Provide a brief description of the project"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-1 d-flex align-items-center mb-1 mb-md-0">
                            <label>Quantity</label>
                        </div>
                        <div class="col-md-3 mb-1 mb-md-0">
                            <input pInputText formControlName="quantity" type="text" pKeyFilter="int" maxlength="2"
                                [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('quantity') }"
                                placeholder="Enter quantity" (keydown)="onKeyDown($event)" />
                        </div>
                        <div class="col-md-2 mb-1 mb-md-0 text-sm-start text-md-end">
                            <p-button label="Add" size="small" styleClass="add-btn" (onClick)="addLivestock()"
                                [rounded]="true" />
                        </div>
                    </div>
                    <div *ngIf="quantityMsg && quantityMsg.length > 0" class="row mt-0">
                        <div class="col-md-1"></div>
                        <div class="col-md-8 qtyMsg">
                            <!--{{quantityMsg[0].detail}}-->
                            <p-messages [value]="quantityMsg" [enableService]="false" [closable]="false" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="mt-3" *ngIf="filteredLivestocks.length > 0">
            <table class="s-table">
                <thead>
                    <tr>
                        <th *ngIf="selectedDept.toLowerCase() == 'ag mechanics'" scope="col" data-label="Division">
                            Division</th>
                        <th scope="col" width="" data-label="Class">Class</th>
                        <th *ngIf="selectedDept.toLowerCase() != 'ag mechanics'" scope="col" width="35%"
                            data-label="Breed/Heifer">
                            Breed/Heifer
                        </th>
                        <th scope="col" width="10%" class="text-end" data-label="Price">Price</th>
                        <th scope="col" width="15%" data-label="Quantity">Quantity</th>
                        <th scope="col" width="10%" class="text-end" data-label="Total">Total</th>
                        <!--<th scope="col" width="5%"></th>-->
                    </tr>
                </thead>
                <tbody>
                    @for (livestock of filteredLivestocks; track $index) {
                    <tr scope="row">
                        @if (livestock.department == selectedDept) {
                        <td *ngIf="selectedDept.toLowerCase() == 'ag mechanics'" scope="col" data-label="Division">
                            <div>
                                <span>
                                    {{livestock.division ?? '--'}}
                                </span>
                                <span *ngIf="livestock.projectDesc">
                                    <i class="pi pi-info-circle" [pTooltip]="livestock.projectDesc"
                                        tooltipPosition="top"></i>
                                </span>
                            </div>
                        </td>
                        <td scope="col" data-label="Class">
                            <div class="class-name">
                                <div>{{livestock.class}}</div>
                                <div *ngIf="livestock.qualityCount" class="show-hide-heifer-details"
                                    (click)="livestock.expanded = !livestock.expanded">
                                    {{livestock.expanded ? 'Hide Details' : 'Show Details'}}
                                </div>
                            </div>
                        </td>
                        <td *ngIf="selectedDept.toLowerCase() != 'ag mechanics'" scope="col" data-label="Breed/Heifer">
                            <!--<div *ngIf="livestock.heiferName">{{livestock.heiferName}}</div>
                            <div *ngIf="!livestock.heiferName">
                                {{livestock.subClass ?? '--'}}
                                <span *ngIf="livestock.sex">-&nbsp;{{livestock.sex}}</span>
                            </div>-->
                            {{getSubClassName(livestock)}}
                        </td>
                        <td scope="col" class="text-end" data-label="Price">
                            <div>{{livestock.priceEach | currency:'USD'}}</div>
                        </td>
                        <td scope="col" data-label="Quantity">
                            <div class="d-flex justify-content-between">
                                <span>{{livestock.count}}</span>
                                <span class="d-flex gap-1">
                                    <i class="pi pi-plus" (click)="onQuantityChange('plus', livestock)"></i>
                                    <i class="pi pi-minus" (click)="onQuantityChange('minus', livestock)"></i>
                                    <i class="pi pi-trash" (click)="removeLivestock(livestock)"></i>
                                </span>
                                <!--<p-inputNumber [showButtons]="true" size="2" [min]="1" [allowEmpty]="false"
                                    [ngModel]="livestock.count"
                                    (ngModelChange)="onQuantityChange($event, livestock)"
                                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />-->
                            </div>
                        </td>
                        <td scope="col" class="text-end" data-label="Total">
                            <div>{{livestock.totalPrice | currency:'USD'}}</div>
                        </td>
                        <!--<td>
                            <div>
                                <button pButton pRipple type="button" icon="pi pi-trash"
                                    class="p-button-rounded p-button-text p-button-danger"
                                    (click)="removeLivestock(livestock)">
                                </button>
                            </div>
                        </td>-->
                        }
                    </tr>
                    <tr *ngIf="livestock.qualityCount">
                        <td *ngIf="livestock.heiferId != -1" class="heifer-details" colspan="5"
                            [ngClass]="{'open': livestock.expanded, 'close': !livestock.expanded}">
                            <div class="row">
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Heifer Registry #</span>
                                    <span class="value">{{livestock.qualityCount.heiferRegistryNo}}</span>
                                </div>
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Date of Birth</span>
                                    <span class="value">{{livestock.qualityCount.dateOfBirth}}</span>
                                </div>
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Breed Name</span>
                                    <span class="value">{{livestock.qualityCount.breedName}}</span>
                                </div>
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Chapter</span>
                                    <span class="value">{{livestock.qualityCount.countyName}}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Billing County</span>
                                    <span class="value">{{livestock.qualityCount.billingCounty}}</span>
                                </div>
                                <div class="col-md-3 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Sire Name</span>
                                    <span class="value">{{livestock.qualityCount.sireName}}</span>
                                </div>
                                <div class="col-md-6 d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Breed</span>
                                    <span class="value">{{livestock.breed}}</span>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="livestock.heiferId == -1" class="heifer-details" colspan="5"
                            [ngClass]="{'open': livestock.expanded, 'close': !livestock.expanded}">
                            <div class="row">
                                <div class="col-md d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Validation Tag Number</span>
                                    <span class="value">{{livestock.validationTagNumber}}</span>
                                </div>
                                <div class="col-md d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Registration Number</span>
                                    <span class="value">{{livestock.registrationNumber}}</span>
                                </div>
                                <div class="col-md d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Breed</span>
                                    <span class="value">{{livestock.breed}}</span>
                                </div>
                                <!--</div>
                            <div class="row mt-2">-->
                                <div class="col-md d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Animal Name</span>
                                    <span class="value">{{livestock.animalName}}</span>
                                </div>
                                <div class="col-md d-flex flex-column align-items-start mb-2 mb-md-0">
                                    <span class="label">Birth Date</span>
                                    <span class="value">{{livestock.birthDate | date: 'MM/dd/yyyy'}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
        <div *ngIf="subTotal > 0" class="row pe-2 mt-2">
            <div class="col-md text-end subtotal">Subtotal : {{subTotal | currency:'USD'}}</div>
        </div>
        <div *ngIf="total > 0" class="row pe-2 mt-3">
            <div class="col-md text-end total">Total Due : {{total | currency:'USD'}}</div>
        </div>
    </div>
    <!--<div class="row mt-2">
        <div class="col-md-8"></div>
        <div class="col-md-4 text-start">Total Due:</div>
    </div>
    <div class="row mt-1">
        <div class="col-md-8"></div>
        <div class="col-md-4 text-start">
            <p-inputNumber mode="currency" currency="USD" [disabled]="true" class="w-100" styleClass="w-100"
                [(ngModel)]="total" />
        </div>
    </div>-->
</section>