import { Livestock } from "../../registration/models/registration";

export default class CommonUtils {

    static isEmpty(data?: string): boolean {
        return (!data || data.trim() == '') ? true : false;
    }

    static isNotEmpty(data?: string): boolean {
        return !CommonUtils.isEmpty(data);
    }

    static equalsIgnoreCase(string1: string, string2: string) {
        if (string1 && string2) {
            return string1.trim().toLowerCase() == string2.trim().toLowerCase()
        } else {
            return false;
        }
    }

    static generateUniqueId(): number {
        //return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
        return Date.now();
    }

    static formatDateToString(date?: Date): string {
        if (date) {
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            return `${month}/${day}/${year}`;
        }
        return '';
    }

    static calculateAge(birthDate?: Date): number {
        if (!birthDate) {
            return -1;
        }

        const birth = new Date(birthDate);

        // Check for valid date
        if (isNaN(birth.getTime())) {
            console.error('Invalid date provided');
            return -1;
        }

        const today = new Date();
        let age = today.getFullYear() - birth.getFullYear();
        const monthDifference = today.getMonth() - birth.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
            age--;
        }

        return age;
    }

    static toTitleCase(value?: string): string {
        if (!value) return '';
        /*return word.toLowerCase().charAt(0).toUpperCase() + word.slice(1);*/

        return value
            .toLowerCase() // Convert the entire string to lowercase first
            .split(/[\s]+/) // Split on spaces
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first letter of each word
            })
            .join(' '); // Join back into a single string
    }

    static getSubClassName(ls: Livestock): string {
        var subClassName = '';

        if (ls.heiferName) {
            var subClassName = ls.heiferName ?? '';

            if (subClassName.length > 0) {
                subClassName += ' - ';
            }

            if (ls.breed) {
                subClassName += ls.breed;
            }
        } else {
            var subClassName = ls.subClass ?? '';

            if (subClassName.length > 0) {
                subClassName += ' - ';
            }

            if (ls.sex) {
                subClassName += ls.sex;
            }
        }

        return subClassName.length > 0 ? subClassName : '--';
    }

    static onDateInput(event: any) {
        // Handlding for character delete (backspace/delete)
        if (event.data == null) {
            return;
        }

        let value = event.target.value;

        // Remove all characters except numbers and '/'
        value = value.replace(/[^0-9/]/g, '');

        // Automatically insert '/' at the appropriate positions
        const parts = value.split('/');
        let formattedValue = '';

        // Handle MM
        if (parts[0]) {
            formattedValue += parts[0].slice(0, 2); // MM
        }
        if (formattedValue.length === 2) {
            formattedValue += '/';
        }

        // Handle DD
        if (parts[1]) {
            formattedValue += parts[1].slice(0, 2); // DD
        }
        if (formattedValue.length === 5) {
            formattedValue += '/';
        }

        // Handle YYYY
        if (parts[2]) {
            formattedValue += parts[2].slice(0, 4); // YYYY
        }

        // Update the input field value only if it's different
        if (formattedValue !== event.target.value) {
            event.target.value = formattedValue;
        }
    }

}