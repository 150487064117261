import { Component, Input } from '@angular/core';
import { PaymentResponse } from '../models/registration';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent {

  @Input() paymentResponse: PaymentResponse | undefined;

}
