<section>
    <form novalidate [formGroup]="formGroup">
        <p-dialog header="Quality Count Verification Failed" [(visible)]="showDialog" [modal]="true" [closable]="true"
            [draggable]="false" [style]="{ width: '50%' }">
            <div class="d-flex">
                <div><i class="pi pi-exclamation-triangle"></i></div>
                <div>Your Quality Counts number cannot be verified. Please
                    complete the course or contact your County Extension Agent or Agricultural Science Teacher for
                    assistance.</div>
            </div>
            <div class="text-end mt-2">
                <p-button [rounded]="true" tabindex="-1" label="Continue" (onClick)="resetQualityCounts()" />
            </div>
        </p-dialog>
        <!--<div class="form-section">
            <div class="section-title">Personal Information</div>
            <div class="m-3">-->
        <div>
            <div class="row">
                <div class="col-md">
                    <p-floatLabel>
                        <input pInputText formControlName="firstName" type="text" class="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('firstName') }"
                            (change)="getQualityCounts()" />
                        <label>Your First Name</label>
                    </p-floatLabel>
                    <span *ngIf="showErrorMessage('firstName')" class="error-message">Please enter First Name</span>
                </div>
                <div class="col-md">
                    <p-floatLabel>
                        <input pInputText formControlName="lastName" type="text" class="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('lastName') }"
                            (change)="getQualityCounts()" />
                        <label>Your Last Name</label>
                    </p-floatLabel>
                    <span *ngIf="showErrorMessage('lastName')" class="error-message">Please enter Last Name</span>
                </div>
                <div class="col-md">
                    <p-floatLabel>
                        <p-calendar formControlName="exhibitorDOB" placeholder="MM/DD/YYYY" [iconDisplay]="'input'"
                            [showIcon]="true" [touchUI]="false" [minDate]="minDate" [maxDate]="maxDate"
                            [defaultDate]="maxDate" maxlength="10" styleClass="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('exhibitorDOB') }"
                            (onSelect)="getQualityCounts()" (onBlur)="getQualityCounts()" (onInput)="onInput($event)" />
                        <label>Date of Birth</label>
                    </p-floatLabel>
                    <span *ngIf="showErrorMessage('exhibitorDOB')" class="error-message">Please select Date of
                        Birth</span>
                </div>
            </div>
        </div>
        <div
            *ngIf="registration.qualityCounts && (!registration.ageDiscloserRequired || (registration.ageDiscloserRequired && registration.ageDiscloserAccepted))">
            <div class="row">
                <div class="col-md">
                    <div class="quality-counts">
                        <span>Quality Counts - </span>
                        <span>{{registration.qualityCounts.qcNumber ?? 'N/A'}}</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="quality-counts">
                        <span>Grade - </span>
                        <span>{{registration.qualityCounts.currentGrade ?? 'N/A'}}</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="quality-counts">
                        <span>Level - </span>
                        <span>{{registration.qualityCounts.level ?? 'N/A'}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md text-center">
                    <span class="additional-info-msg">Please Enter Additional Information</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <p-floatLabel>
                        <input pInputText formControlName="address" type="text" class="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('address') }"
                            (change)="getAddress()" />
                        <label>Mailing Address</label>
                    </p-floatLabel>
                    <span *ngIf="showErrorMessage('address')" class="error-message">Please enter Mailing
                        Address</span>
                </div>
                <div class="col-md">
                    <p-floatLabel>
                        <input pInputText formControlName="city" type="text" class="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('city') }" (change)="getAddress()" />
                        <label>City</label>
                    </p-floatLabel>
                    <span *ngIf="showErrorMessage('city')" class="error-message">Please enter City</span>
                </div>
                <div class="col-md">
                    <p-floatLabel>
                        <p-dropdown formControlName="state" [options]="states" optionLabel="name"
                            optionValue="abbreviation" styleClass="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('state') }"
                            [panelStyle]="{ 'position': 'absolute', 'bottom': '100%', 'transform': 'translateY(-5px)' }"
                            (onChange)="getAddress()" />
                        <label>State</label>
                    </p-floatLabel>
                    <span *ngIf="showErrorMessage('state')" class="error-message">Please select State</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <p-floatLabel>
                        <input pInputText formControlName="zipCode" pKeyFilter="int" minlength="5" maxlength="5"
                            type="text" class="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('zipCode') }" />
                        <label>Zip Code</label>
                    </p-floatLabel>
                    <span *ngIf="showErrorMessage('zipCode')" class="error-message">Please enter Zip Code</span>
                </div>
                <div class="col-md">
                    <p-floatLabel>
                        <input pInputText formControlName="email" type="text" class="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('email') }" />
                        <label>Email</label>
                    </p-floatLabel>
                    <span
                        *ngIf="showErrorMessage('email') && formGroup.controls['email'].errors && formGroup.controls['email'].errors['required']"
                        class="error-message">Please enter Email</span>
                    <span *ngIf="formGroup.controls['email'].errors && formGroup.controls['email'].errors['pattern']"
                        class="error-message">Please enter valid Email</span>
                </div>
                <div class="col-md">
                    <p-floatLabel>
                        <p-inputMask formControlName="phone" mask="(999) 999-9999" slotChar="#" styleClass="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('phone') }" />
                        <label>Phone</label>
                    </p-floatLabel>
                    <span *ngIf="showErrorMessage('phone')" class="error-message">Please enter Phone</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <p-floatLabel>
                        <p-dropdown formControlName="county" [options]="counties" [filter]="true" optionLabel="name"
                            optionValue="name" styleClass="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('county') }"
                            [panelStyle]="{ 'position': 'absolute', 'bottom': '100%', 'transform': 'translateY(-5px)' }"
                            (onChange)="onCountyChange()" />
                        <label>County</label>
                    </p-floatLabel>
                    <span *ngIf="showErrorMessage('county')" class="error-message">Please select County</span>
                </div>
                <div class="col-md">
                    <p-floatLabel>
                        <p-dropdown formControlName="chapter" [options]="chapters" [filter]="true" optionLabel="chapter"
                            optionValue="chapter" styleClass="w-100"
                            [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('chapter') }"
                            [panelStyle]="{ 'position': 'absolute', 'bottom': '100%', 'transform': 'translateY(-5px)' }" />
                        <label>Chapter</label>
                    </p-floatLabel>
                    <span *ngIf="showErrorMessage('chapter')" class="error-message">Please select Chapter</span>
                </div>
                <div class="col-md"></div>
            </div>
        </div>
        <!--</div>
        </div>-->
    </form>
</section>