<section>
    <div class="reg-success">
        <div class="row w-100">
            <div class="col"></div>
            <div class="col d-flex justify-content-center">
                <i class="pi pi-check"></i>
            </div>
            <div class="col d-flex align-items-center justify-content-end">
                <i class="pi pi-print"></i>
                <i class="pi pi-download ms-2"></i>
            </div>
        </div>
        <div>Your entry to the 2025 Hill District Grandstand Show has been submitted.</div>
        <div>Your entry has been sent to your Ag Teacher/County Agent for approval. You will receive an
            email confirmation that your entry has been submitted and another when your entry has been approved by your
            Ag Teacher/County Agent.</div>
        <!--<div>Transaction ID: {{paymentResponse?.transId}}</div>-->
    </div>
</section>