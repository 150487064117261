import { Component, inject, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { RegistrationService } from '../registration.service';
import { Class, Department, Division, SubClass } from '../models/division-classes';
import { Livestock, Registration } from '../models/registration';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../shared/service/notification.service';
import { ConfirmationService, Message } from 'primeng/api';
import { QualityCountsHeifers } from '../models/quality-counts';
import { livestockValidationRules } from '../models/livestock-validation-counts';
import CommonUtils from '../../shared/utils/common.utils';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { HttpErrorResponse } from '@angular/common/http';
import { OverlayPanel } from 'primeng/overlaypanel';
import { format } from 'date-fns';

@Component({
  selector: 'app-livestocks',
  templateUrl: './livestocks.component.html',
  styleUrl: './livestocks.component.scss'
})
export class LivestocksComponent implements OnInit {

  private fb = inject(FormBuilder);
  private registrationService = inject(RegistrationService);
  private notificationService = inject(NotificationService);
  private confirmationService = inject(ConfirmationService);

  @Input() registration: Registration = {};
  @Input() orgId: number = 0;

  private livestocks: Livestock[] = [];

  departments?: Department[];
  divisions?: Division[] = [];
  classes?: Class[] = [];
  subClasses?: SubClass[] = [];
  qualityCountHeifers?: QualityCountsHeifers[] = [];
  filteredLivestocks: Livestock[] = [];
  selectedDept: string = '';
  showSheepSex: boolean = false;
  showTurkeySex: boolean = false;
  subTotal: number = 0;
  total: number = 0;
  quantityMsg: Message[] = [];
  minHeiferBirthDate = new Date(2021, 0, 1);
  maxHeiferBirthDate = new Date(2024, 6, 1);

  onDateInput = CommonUtils.onDateInput;

  livestockForm: FormGroup;
  heiferForm: FormGroup;

  constructor() {
    this.livestockForm = this.fb.group({
      division: [null, [Validators.required]],
      class: [null, [Validators.required]],
      subClass: [null],
      sex: [null],
      breed: [null],
      projectDesc: [null],
      quantity: [null, [Validators.required]],
    });

    this.heiferForm = this.fb.group({
      validationTagNumber: [null, [Validators.required]],
      animalName: [null, [Validators.required]],
      breed: [null, [Validators.required]],
      birthDate: [null, [Validators.required]],
      registrationNumber: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
    if (this.registration.liveStocks) {
      this.livestocks = this.registration.liveStocks;
    }

    this.registrationService.getDivisionsAndClasses(this.orgId, this.registration.county).subscribe({
      //this.registrationService.getDivisionsAndClasses(6, 'Zavala').subscribe({
      next: (response) => {
        this.departments = response;
        this.onDeptChange(this.departments[0].department);
        this.onQuantityChange();
      }, error: (err) => {
        console.error(err);
        this.notificationService.error();
      }
    })
  }

  showErrorMessage(formControlName: string): boolean {
    var fc = this.livestockForm.controls[formControlName];
    return (fc.touched || fc.dirty) && fc.invalid;
  }

  showHeiferErrorMessage(formControlName: string): boolean {
    var fc = this.heiferForm.controls[formControlName];
    return (fc.touched || fc.dirty) && fc.invalid;
  }

  private filterLivestocksBySelectedDept() {
    this.filteredLivestocks = this.livestocks.filter(ls => ls.department == this.selectedDept);
    this.subTotal = 0;
    this.filteredLivestocks.forEach(ls => {
      this.subTotal += ls.totalPrice;
    });
  }

  onDeptChange(deptName: string) {
    this.selectedDept = deptName;
    this.filterLivestocksBySelectedDept();
    this.livestockForm.reset();
    this.heiferForm.reset();

    this.divisions = this.departments
      ?.filter(d => d.department == this.selectedDept).at(0)?.divisions
      ?.sort((a, b) => {
        //a.division.localeCompare(b.division)
        const containsOtherA = a.division.toLowerCase().includes('other');
        const containsOtherB = b.division.toLowerCase().includes('other');

        if (containsOtherA && !containsOtherB) {
          return 1; // 'a' comes after 'b'
        }
        if (!containsOtherA && containsOtherB) {
          return -1; // 'a' comes before 'b'
        }

        // Both contain "Other" or neither does, sort alphabetically
        return a.division.localeCompare(b.division);
      });

    /*this.classes = [];
    this.subClasses = [];
    this.qualityCountHeifers = [];
    this.quantityMsg = [];
    this.showSheepSex = false;
    this.livestockForm.controls['sheepSex'].clearValidators();
    this.livestockForm.controls['sheepSex'].setValue('');*/

    this.clearForm();

    if (this.divisions && !CommonUtils.equalsIgnoreCase(this.selectedDept, 'ag mechanics')) {
      this.livestockForm.controls['division'].clearValidators();
      this.livestockForm.controls['projectDesc'].clearValidators();

      this.divisions.flatMap(d => d.classes).forEach(c => {
        if (c) {
          this.classes?.push(c);
        }
      });

      this.classes?.sort((a, b) => {
        //a.classDescription.localeCompare(b.classDescription)
        const containsOtherA = a.classDescription.toLowerCase().includes('other');
        const containsOtherB = b.classDescription.toLowerCase().includes('other');

        if (containsOtherA && !containsOtherB) {
          return 1; // 'a' comes after 'b'
        }
        if (!containsOtherA && containsOtherB) {
          return -1; // 'a' comes before 'b'
        }

        // Both contain "Other" or neither does, sort alphabetically
        return a.classDescription.localeCompare(b.classDescription);
      });
    } else {
      this.livestockForm.controls['division'].addValidators(Validators.required);
      this.livestockForm.controls['projectDesc'].addValidators(Validators.required);
    }

    this.updateSubclassValidationRule();
    this.livestockForm.markAsPristine();
    this.livestockForm.updateValueAndValidity();
    this.livestockForm.controls['division'].updateValueAndValidity();
    this.livestockForm.controls['projectDesc'].updateValueAndValidity();
  }

  onDivisionChange() {
    const selectedDivisionId: string = this.livestockForm.controls['division'].value;

    this.classes = this.divisions
      ?.filter(d => d.divisionId == selectedDivisionId)[0].classes
      ?.sort((a, b) => {
        //a.classDescription.localeCompare(b.classDescription)
        const containsOtherA = a.classDescription.toLowerCase().includes('other');
        const containsOtherB = b.classDescription.toLowerCase().includes('other');

        if (containsOtherA && !containsOtherB) {
          return 1; // 'a' comes after 'b'
        }
        if (!containsOtherA && containsOtherB) {
          return -1; // 'a' comes before 'b'
        }

        // Both contain "Other" or neither does, sort alphabetically
        return a.classDescription.localeCompare(b.classDescription);
      });
  }

  onClassChange() {
    const selectedClassId: number = this.livestockForm.controls['class'].value;
    const klass = this.classes?.filter(c => c.classId == selectedClassId)[0];

    if (!CommonUtils.equalsIgnoreCase(this.selectedDept, 'ag mechanics')) {
      if (klass && klass.classDescription.toLowerCase().indexOf('heifers') > 0) {
        if (!klass.qualityCounts || klass.qualityCounts.length == 0) {
          this.registrationService.getQualityCountsHeifers(this.registration.firstName, this.registration.lastName)
            //this.registrationService.getQualityCountsHeifers('Olivia', 'Adams')
            .subscribe({
              next: response => {
                klass.qualityCounts = response.sort((a, b) => a.displayName.localeCompare(b.displayName));
                klass.qualityCounts.push({
                  displayName: 'Other',
                  id: -1
                });
                this.qualityCountHeifers = response;
                this.updateSubclassValidationRule();
              },
              error: (err: HttpErrorResponse) => {
                if (err.status == 404) {
                  klass.qualityCounts = [{
                    displayName: 'Other',
                    id: -1
                  }];
                  this.qualityCountHeifers = [{
                    displayName: 'Other',
                    id: -1
                  }];
                } else {
                  console.error(err);
                  this.notificationService.error();
                }
              }
            });
        } else {
          this.qualityCountHeifers = klass.qualityCounts;
        }

        this.subClasses = [];
        this.livestockForm.controls['quantity'].setValue(1);
      } else {
        this.livestockForm.controls['quantity'].setValue(null);
        this.qualityCountHeifers = [];
        this.subClasses = klass?.subClasses?.sort((a, b) => {
          //a.name.localeCompare(b.name)
          const containsOtherA = a.name.toLowerCase().includes('other');
          const containsOtherB = b.name.toLowerCase().includes('other');

          if (containsOtherA && !containsOtherB) {
            return 1; // 'a' comes after 'b'
          }
          if (!containsOtherA && containsOtherB) {
            return -1; // 'a' comes before 'b'
          }

          // Both contain "Other" or neither does, sort alphabetically
          return a.name.localeCompare(b.name);
        });
      }

      if (klass && CommonUtils.equalsIgnoreCase(klass?.classDescription, 'Breeding Sheep')) {
        this.showSheepSex = true;
        this.livestockForm.controls['sex'].addValidators(Validators.required);
      } else if (klass && CommonUtils.equalsIgnoreCase(klass?.classDescription, 'Market Turkeys')) {
        this.showTurkeySex = true;
        this.livestockForm.controls['sex'].addValidators(Validators.required);
      } else {
        this.showSheepSex = false;
        this.showTurkeySex = false;
        this.livestockForm.controls['sex'].clearValidators();
        this.livestockForm.controls['sex'].setValue(null);
      }

      this.livestockForm.controls['sex'].updateValueAndValidity();

      this.showQtyNotification(klass?.classDescription);
      this.updateSubclassValidationRule();
    } else {
      // set default quantity to 1 for ag mechanics
      this.livestockForm.controls['quantity'].setValue(1);
    }
  }

  private updateSubclassValidationRule() {
    if ((this.subClasses && this.subClasses.length > 0) || this.qualityCountHeifers && this.qualityCountHeifers.length > 0) {
      this.livestockForm.controls['subClass'].addValidators(Validators.required);
    } else {
      this.livestockForm.controls['subClass'].clearValidators();
    }
    this.livestockForm.controls['subClass'].updateValueAndValidity();
  }

  addLivestock() {
    this.livestockForm.markAllAsTouched();
    this.livestockForm.updateValueAndValidity();

    if (this.livestockForm.invalid) {
      this.notificationService.error('Please provide all required details.');
      return;
    }

    const selectedDivisionId: string = this.livestockForm.controls['division'].value;
    const selectedClassId: number = this.livestockForm.controls['class'].value;
    const selectedSubClassId: string = this.livestockForm.controls['subClass'].value;
    const quantity: number = this.livestockForm.controls['quantity'].value;

    const d = this.divisions?.find(d => d.divisionId == selectedDivisionId);
    const c = this.classes?.find(c => c.classId == selectedClassId);
    var sc: SubClass | undefined;
    var qc: QualityCountsHeifers | undefined;

    if (c?.subClasses && c.subClasses.length > 0) {
      sc = this.subClasses?.find(sc => sc.id.toString() == selectedSubClassId);
    } else if (c?.qualityCounts && c.qualityCounts.length > 0) {
      qc = c.qualityCounts?.find(sc => sc.id == +selectedSubClassId);

      // check for heifer - others
      if (qc?.id == -1) {
        this.heiferForm.markAllAsTouched();
        this.heiferForm.updateValueAndValidity();

        if (this.heiferForm.invalid) {
          this.showDialog = true;
          return;
        }
      }
    }

    if (c) {
      const totalPrice: number = quantity * c.fees.fee;
      const heiferDate = format(this.heiferForm.controls['birthDate'].value, 'MM-dd-yyyy');
      const livestock: Livestock = {
        //id: CommonUtils.generateUniqueId(),
        //exhibitor_Id: this.orgId,
        department: this.selectedDept,
        division: d?.division,
        divisionId: d?.divisionId,
        class: c.classDescription,
        classId: c.classId,
        subClassId: sc?.id,
        subClass: sc?.name,
        sex: this.livestockForm.controls['sex'].value,
        heiferId: qc?.id,
        heiferName: qc?.displayName,
        qualityCount: qc,
        count: quantity,
        priceEach: c.fees.fee,
        totalPrice: totalPrice,
        expanded: true,
        projectDesc: this.livestockForm.controls['projectDesc'].value,
        validationTagNumber: this.heiferForm.controls['validationTagNumber'].value,
        registrationNumber: this.heiferForm.controls['registrationNumber'].value,
        birthDate: heiferDate,
        animalName: this.heiferForm.controls['animalName'].value,
        breed: this.showHeiferBreeds ? this.livestockForm.controls['breed'].value : this.heiferForm.controls['breed'].value
      }

      // check if already added
      if (this.livestocks.findIndex(
        ls => ls.department == livestock.department
          && ls.class == livestock.class
          && ls.subClass == livestock.subClass
          && ls.sex == livestock.sex
          && ls.qualityCount == livestock.qualityCount
          && ls.heiferId != -1
      ) >= 0) {
        this.notificationService.error("This entry has already been added. Please change the quantity from the list.");
      } else if (this.validateLivestockQty(livestock, quantity)) {
        this.livestocks.push(livestock);
        this.total += totalPrice;

        /*this.onClassChange();
        this.filterLivestocksBySelectedDept();
        this.quantityMsg = [];
        this.livestockForm.reset();*/
        //this.clearForm();
        this.onDeptChange(this.selectedDept);
        this.showDialog = false;
        this.notificationService.clear();
      }
    }
  }

  removeLivestock(toRemove: Livestock) {
    this.confirmationService.confirm({
      header: 'Delete Confirmation',
      message: 'Do you want to remove this entry?',
      defaultFocus: 'none',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-outlined p-button-danger",
      rejectButtonStyleClass: "p-button",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        const indexToRemove = this.livestocks.findIndex(ls => ls.classId == toRemove.classId);

        if (indexToRemove !== -1) {
          const removedItem: Livestock[] = this.livestocks.splice(indexToRemove, 1);
          this.total -= removedItem[0].totalPrice;
          this.filterLivestocksBySelectedDept();
        }
      }
    });
  }

  private clearForm() {
    //this.onClassChange();
    //this.filterLivestocksBySelectedDept();
    this.classes = [];
    this.subClasses = [];
    this.qualityCountHeifers = [];
    this.quantityMsg = [];
    this.showSheepSex = false;
    this.showTurkeySex = false;
    this.showHeiferBreeds = false;
    this.livestockForm.reset();
    this.livestockForm.controls['sex'].clearValidators();
    this.livestockForm.controls['sex'].setValue('');
  }

  onQuantityChange(action?: 'plus' | 'minus', livestock?: Livestock) {
    if (action && livestock) {
      const oldValue: number = livestock.count;
      var newValue: number = oldValue;

      action == 'plus' ? newValue++ : newValue--;

      if (newValue < 1 || newValue > 99) {
        return;
      }

      if (newValue > 1 && newValue > livestock.count) {
        if (this.validateLivestockQty(livestock, newValue, oldValue)) {
          livestock.count = newValue;
        } else {
          livestock.count = oldValue;
        }
      } else if (newValue > 0 && newValue <= livestock.count) {
        livestock.count = newValue;
      }
    }

    var newTotal: number = 0;

    this.livestocks.forEach(ls => {
      ls.totalPrice = ls.count * ls.priceEach;
      newTotal += ls.totalPrice;
    });

    this.total = newTotal;

    this.filterLivestocksBySelectedDept();
  }

  /*private showQtyNotification(klass?: string) {
    if (klass) {
      var msg = undefined;

      const ruleIndex = livestockValidationRules.findIndex(rule => rule.warningOnly
        && CommonUtils.equalsIgnoreCase(rule.department, 'Breeding Show')
        && CommonUtils.equalsIgnoreCase(rule.className, klass));

      if (ruleIndex >= 0) {
        const rule = livestockValidationRules[ruleIndex];

        if (rule.maxQtyPerSubClass && rule.maxQtyPerSubClass > 0) {
          msg = `Exhibitors are limited to ${rule.maxQtyPerClass} entries (${rule.maxQtyPerSubClass} per breed)`;
        } else if (rule.maxQtyPerClass && rule.maxQtyPerClass > 0) {
          msg = `Exhibitors are limited to ${rule.maxQtyPerClass} entries`;
        }
      }

      if (msg) {
        this.quantityMsg = [{ severity: 'warn', detail: msg }];
      } else {
        this.quantityMsg = [];
      }
    }
  }*/

  private showQtyNotification(klass?: string) {
    if (klass) {
      var msg = undefined;

      const ruleIndex = livestockValidationRules.findIndex(rule => CommonUtils.equalsIgnoreCase(rule.className, klass));

      if (ruleIndex >= 0) {
        const rule = livestockValidationRules[ruleIndex];

        if (rule.maxQtyPerSubClass && rule.maxQtyPerSubClass > 0) {
          msg = `Exhibitors are limited to ${rule.maxQtyPerClass} entries (${rule.maxQtyPerSubClass} per ${this.showTurkeySex ? 'sex' : 'breed'})`;
        } else if (rule.maxQtyPerClass && rule.maxQtyPerClass > 0) {
          msg = `Exhibitors are limited to ${rule.maxQtyPerClass} entries`;
        }

        if (rule.messageSuffix) {
          msg += ` ${rule.messageSuffix}`;
        }
      }

      if (msg) {
        this.quantityMsg = [{ severity: 'warn', detail: msg }];
      } else {
        this.quantityMsg = [];
      }
    }
  }

  private validateLivestockQty(livestock: Livestock, newCount: number, oldCount: number = 0): boolean {
    const ruleIndex = livestockValidationRules.findIndex(rule =>
      (rule.warningOnly == undefined || !rule.warningOnly)
      && CommonUtils.equalsIgnoreCase(rule.department, livestock.department)
      && CommonUtils.equalsIgnoreCase(rule.className, livestock.class));

    /*livestockValidationRules.forEach(rule => {
      if (CommonUtils.equalsIgnoreCase(rule.department, livestock.department) &&
        CommonUtils.equalsIgnoreCase(rule.className, livestock.class)
      ) {*/
    if (ruleIndex >= 0) {
      const rule = livestockValidationRules[ruleIndex];

      if (rule.maxQtyPerSubClass > 0) {
        if ((livestock.subClass || livestock.heiferId == -1 || (!livestock.subClass && livestock.sex) || livestock.qualityCount) && newCount > rule.maxQtyPerSubClass) {
          this.notificationService.error(`Exhibitors are limited to ${rule.maxQtyPerSubClass} quantity for ${livestock.subClass ?? livestock.sex ?? livestock.qualityCount?.displayName}.`);
          return false;
        }
      }

      if (rule.maxQtyPerClass > 0) {
        const totalPerClass = this.livestocks
          .filter(ls => ls.department == livestock.department && ls.class == livestock.class)
          .flatMap(ls => +ls.count)
          .reduce((total, current) => total + current, 0);

        if (totalPerClass + (newCount - oldCount) > rule.maxQtyPerClass) {
          this.notificationService.error(`Exhibitors are limited to ${rule.maxQtyPerClass} quantity for ${rule.className}.`);
          return false;
        }
      }
    }

    return true;
  }

  getLivestocks(): Livestock[] {
    return this.livestocks;
  }

  showDialog: boolean = false;
  showHeiferBreeds: boolean = false;
  today = new Date();
  heiferBreeds: { name: string; value: any }[] = [
    { name: 'American', value: 'American' },
    { name: 'British', value: 'British' },
    { name: 'European', value: 'European' }
  ];

  onHeiferChange(event: DropdownChangeEvent) {
    if (event.value == -1) {
      this.showDialog = true;
      this.showHeiferBreeds = false;
      this.livestockForm.controls['breed'].clearValidators();
    } else {
      this.showHeiferBreeds = true;
      this.livestockForm.controls['breed'].addValidators(Validators.required);

    }
    this.livestockForm.controls['breed'].updateValueAndValidity();
  }

  addHeiferDetails() {
    this.heiferForm.markAllAsTouched();
    this.heiferForm.updateValueAndValidity();

    if (this.heiferForm.valid) {
      this.addLivestock();
    }
  }

  @ViewChildren(OverlayPanel) overlayPanels!: QueryList<OverlayPanel>;

  showOverlayPanel(event: Event, index: number) {
    const overlayPanel = this.overlayPanels.toArray()[index];
    if (overlayPanel) {
      overlayPanel.toggle(event);
    }
  }

  getSubClassName = CommonUtils.getSubClassName;

  onKeyDown(event: KeyboardEvent, allowZeros: boolean = true) {
    const key = event.key;

    // Allow: backspace, delete, tab, escape, enter and arrow keys
    if (['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(key)) {
      return; // Allow the key press
    }

    // Create regex based on allowZeros
    const regex = allowZeros ? /^[0-9]$/ : /^[1-9]$/;

    // If the key pressed is not a digit, prevent the keypress
    if (!regex.test(key)) {
      event.preventDefault();
    }
  }

}
