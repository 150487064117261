import { Component, inject, Input } from '@angular/core';
import { Registration } from '../models/registration';
import { NotificationService } from '../../shared/service/notification.service';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrl: './additional-info.component.scss'
})
export class AdditionalInfoComponent {

  private notificationService = inject(NotificationService);

  @Input() registration: Registration = {};
  @Input() w9File: File | undefined;

  supportedFileTypes: string[] = ['application/pdf'];
  supportedFileExtensions: string = '.pdf';
  showDialog: boolean = false;

  onDocumentsUpload(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      if (!this.supportedFileTypes.includes(file.type)) {
        this.notificationService.error(`You can upload ${this.supportedFileExtensions} files only.`);
      }

      this.w9File = file;
    }
  }

  onWaiverAccept() {
    this.showDialog = false;
    this.registration.waiverComplete = true;
  }

  onAgeDiscloserChange(value: boolean) {
    this.registration.ageDiscloserAccepted = value;
  }

  /*onDialogClose() {
    this.iFrameUrl = undefined;
    if (this.signingDetails) {
      this.registrationService.getSigningStatus(this.signingDetails.id).subscribe({
        next: (response) => {
          this.signingCompleted = false;

          if (this.signingDetails) {
            if (response) {
              if (response.status?.toLowerCase() == "sent") {
                this.signingDetails.status = "in-progress";
              } else if (response.status?.toLowerCase() == "completed") {
                this.signingDetails.status = "success";
                this.signingCompleted = true;
              } else {
                this.signingDetails.status = response.status.toLowerCase();
              }
            }
          }
        },
        error: err => {
          console.error(err);
          this.notificationService.error();
        }
      });
    }
  }*/

  /*getSigningDetails(): SigningDetails | undefined {
    return this.signingDetails;
  }*/

  getW9File(): File | undefined {
    return this.w9File;
  }

}
