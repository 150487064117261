export class LivestockValidationRule {
    department!: string;
    className!: string;
    maxQtyPerClass!: number;
    maxQtyPerSubClass!: number;
    warningOnly?: boolean;
    messageSuffix?: string;
}

export const livestockValidationRules: LivestockValidationRule[] =
    [
        {
            department: 'Breeding Show',
            className: 'Breeding Heifers',
            maxQtyPerClass: 6,
            maxQtyPerSubClass: 2,
            //warningOnly: true
        },
        {
            department: 'Breeding Show',
            className: 'Breeding Sheep',
            maxQtyPerClass: 2,
            maxQtyPerSubClass: 0,
            warningOnly: true,
            messageSuffix: 'per class'
        },
        {
            department: 'Breeding Show',
            className: 'Crossbred Breeding Gilts',
            maxQtyPerClass: 3,
            maxQtyPerSubClass: 0,
            //warningOnly: true
        },
        {
            department: 'Breeding Show',
            className: 'Purebred Breeding Gilts',
            maxQtyPerClass: 6,
            maxQtyPerSubClass: 3,
            //warningOnly: true
        },
        {
            department: 'Market Show',
            className: 'Market Barrows',
            maxQtyPerClass: 2,
            maxQtyPerSubClass: 0
        },
        {
            department: 'Market Show',
            className: 'Market Goats',
            maxQtyPerClass: 2,
            maxQtyPerSubClass: 0
        },
        {
            department: 'Market Show',
            className: 'Market Lambs',
            maxQtyPerClass: 2,
            maxQtyPerSubClass: 0
        },
        {
            department: 'Market Show',
            className: 'Market Steers',
            maxQtyPerClass: 2,
            maxQtyPerSubClass: 0
        },
        {
            department: 'Market Show',
            className: 'Market Turkeys',
            maxQtyPerClass: 2,
            maxQtyPerSubClass: 1
        }
    ];