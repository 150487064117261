<section>
    <div class="additional-info-container">
        <div class="row">
            <div class="col d-flex">
                <p-checkbox [binary]="true" [disabled]="true" [ngModel]="registration.waiverComplete" />
                <label class="ms-2">
                    Please read and agree to the <span class="link" (click)="showDialog = true">Waiver Form.</span>
                </label>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col d-flex flex-column flex-md-row justify-content-center justify-content-md-start">
                <div>
                    <input #docUpload type="file" class="d-none" [accept]="supportedFileExtensions"
                        (change)="onDocumentsUpload($event)" />
                    <p-checkbox [binary]="true" [disabled]="true" [ngModel]="w9File ? true : false" />
                    <span class="ms-2 link" (click)="docUpload.click()">Upload Signed W-9</span>
                </div>
                <span *ngIf="w9File" class="uploaded-file-name">
                    <i class="pi pi-file-pdf me-1"></i>
                    {{w9File.name}}
                </span>
            </div>
            <div class="mt-4">
                Please click the above link to upload signed W-9 before proceeding to the payment. <a class="link"
                    href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" rel="noopener">Download W-9
                    Template</a>
            </div>
        </div>
    </div>
    <p-dialog header="Waiver Form" [(visible)]="showDialog" [modal]="true" [draggable]="false">
        <div class="row mt-2">
            <div class="col">
                <p>
                    Release of Liability and Indemnity Agreement In consideration, the receipt and sufficiency of which
                    is hereby acknowledged, for being allowed entry into and participation in activities (the
                    “Activities”) associated with the Hill District Grandstand Show, hereinafter “HDGS”, the undersigned
                    hereby enters into this RELEASE OF LIABILITY AND INDEMNITY AGREEMENT (this “Agreement”) as of the
                    date set forth below:
                </p>
                <p>
                    1. <strong>ACKNOWLEDGEMENT OF RISKS:</strong> The undersigned recognizes and understands that there
                    are risks associated with their participation in the Activities including, but not limited to,
                    bodily injury or death to persons and damage to property. The undersigned further acknowledges and
                    understands that they will be held liable and responsible for any and all damage to persons,
                    livestock, vehicles, property and/or improvements to property that is caused by them and/or any
                    persons (including, but not limited to, minors) under their care and control, and that arise out of,
                    or are related to, the undersigned’s entry into and participation in the Activities.
                </p>
                <p>
                    2. <strong>APPLICABILITY AND SCOPE OF RELEASES AND INDEMNITIES:</strong> For purposes of this
                    Agreement, “Claims” shall mean any past, present and future claims, losses, costs, expenses,
                    liabilities, demands, or causes of action, and costs of defense or settlement (including, without
                    limitation, attorneys’ fees and court costs). For purposes of this Agreement, the term “Released
                    Parties” shall mean HDGS, the Cities of Fredericksburg, Llano, Comfort, and Kerrville, Texas,
                    Gillespie, Llano, Kendall, and Kerr Counties, State of Texas and their respective present and former
                    officers, directors, members, council members, commissioners, subsidiaries, affiliates, employees,
                    staff, volunteers, agents and any other person, firm, corporation or entity bound to defend or pay
                    judgments against them. The releases, waivers and indemnities contained in this Agreement expressly
                    shall apply regardless of whether the Claims to be released, waived or indemnified against arise, or
                    are alleged to arise, from (i) NEGLIGENCE (WHETHER SOLE, JOINT OR CONCURRENT), GROSS NEGLIGENCE,
                    NEGLIGENCE PER SE, and/or STRICT LIABILITY, of the Released Parties; (ii) personal injury, death or
                    property damage; (iii) acts under the Texas Deceptive Trade Practices Act (“DTPA”); (iv) acts of any
                    other persons or guests; (v) theft, burglary, assault, or other crimes; (vi) fire, water, wind, rain
                    and/or smoke and /or (vii) any other risks and hazards associated with the undersigned’s entry into
                    and participation in the Activities, including, but not limited to, the general conditions at the
                    Activities, animals both wild and domestic that may be diseased and/or potentially dangerous,
                    persons with firearms both on and off the premises used in connection with the Activities, and the
                    driving or riding in any vehicles, whether belonging to Released Parties or to other persons.
                </p>
                <p>
                    3. <strong>RELEASE FROM LIABILITY: </strong>The undersigned hereby releases, acquits and forever
                    discharges any and all Claims against any of the Released Parties that arise from or relate to their
                    entry and participation in the Activities—including, but not limited to, the types of claims
                    enumerated in Paragraph 2—and agree not to sue any of the Released Parties for such Claims. Without
                    limiting the foregoing, the undersigned agrees that the Released Parties shall not be liable to
                    them, their family, agents, employees or their guests, for personal injury, property damage, or any
                    other Claims arising from or related to the undersigned’s entry into and participation in the
                    Activities.
                </p>
                <p>
                    4. <strong>AGREEMENT TO INDEMNIFY AND HOLD HARMLESS: </strong>The undersigned agrees to INDEMNIFY
                    and HOLD HARMLESS the Released Parties against any and all Claims arising from or related to the
                    undersigned’s entry and participation in the Activities—including, but not limited to, the types of
                    Claims enumerated in Paragraph 2. In addition, and without limiting the foregoing, the undersigned
                    agrees to INDEMNIFY the Released Parties for any Claims for injuries to any minors under their care
                    and control and/or his or her parent/guardian, and for any Claims asserted by, through or under the
                    undersigned, 2024 HDGS – Updated 5/22/2023 59 arising from or related to the undersigned’s entry
                    into and participation in the Activities—including, but not limited to, the types of Claims
                    enumerated in Paragraph 2. As used herein, “INDEMNIFY” means to agree to assume the Released
                    Parties’ liability in a situation, thereby relieving them of responsibility, and/or reimbursing the
                    Released Party for Claims asserted against them.
                </p>
                <p>
                    5. <strong>COVID-19:</strong> We need your cooperation to comply with all local and state guidelines
                    and procedures to keep our exhibitors, families and communities safe.
                </p>
                <p>
                    6. <strong>PHOTOGRAPH/INTERVIEW RELEASE AND INDEMNITY AGREEMENT:</strong> The undersigned grants
                    permission to be photographed, videoed or interviewed in connection with the Activities. The
                    undersigned understands that any such photograph, video or interview may be used by the Released
                    Parties or television, film, video, visual, graphic or printed media. The undersigned agrees to
                    RELEASE and INDEMNIFY the Released Parties with respect to any Claims related to the usage of such
                    photographs or interviews by the Released Parties or any media—including, but not limited to, the
                    types of Claims enumerated in Paragraph 2.
                </p>
                <p>
                    7. <strong>CONFIDENTIALITY: </strong>Participant acknowledges that during his/her participation in
                    the Activities that Participant may have access to, receive or become acquainted with certain
                    non-public and confidential information, including but not limited to various trade secrets,
                    inventions, innovations, processes, information, records, membership lists, donor lists, contracts,
                    and specifications owned or licensed by HDGS and/or used by HDGS in connection with the Activities
                    and the operation of HDGS. All such non-public and confidential information is hereinafter called
                    the “Confidential Information” and shall remain the property of HDGS at all times. Participant will
                    keep HDGS’s Confidential Information confidential from all third parties. Participant shall not
                    disclose any of the aforesaid Confidential Information, directly or indirectly, or use any of it in
                    any manner, either during the term of this Agreement or at any time thereafter, except as required
                    in the course and scope of Participant’s participation in the Activities. Participant acknowledges
                    that the Confidential Information is unique and valuable, and that breach of the obligations of this
                    Agreement regarding such Confidential Information will result in irreparable injury to HDGS for
                    which monetary damages alone would not be an adequate remedy. Therefore, Participant agrees that in
                    the event of a breach or threatened breach of such provisions, HDGS shall be entitled to injunctive
                    or other equitable relief as a remedy for any such breach or anticipated breach without the
                    necessity of posting a bond. Any such relief shall be in addition to and not in lieu of any
                    appropriate relief in the way of monetary damages. All Confidential Information shall be and remain
                    the property of HDGS, and Participant shall return all of such Confidential Information to HDGS upon
                    the earlier of the termination of this Agreement or Participant’s termination as a member/volunteer
                    of HDGS.
                </p>
                <p>
                    8. <strong>COMPLETE AND FINAL RELEASE AND INDEMNITY AGREEMENT: </strong>As further inducement to
                    HDGS to permit the undersigned’s entry into and participation in the Activities, the undersigned
                    represents that they thoroughly and completely understand that this is a complete and final release
                    and indemnity agreement, that they are freely and voluntarily entering into this Agreement, and that
                    no representations, promises or statements made by any of the Released Parties, or any agent,
                    attorney or other representative of any of the Released Parties has influenced the undersigned in
                    causing them to sign this Agreement.
                </p>
                <p>
                    <strong>MISCELLANEOUS PROVISIONS:</strong> The undersigned acknowledges receipt of all rules and
                    guidelines that govern or apply to the Activities, and further acknowledges that they have reviewed
                    and will abide by such rules and guidelines. The undersigned understands that this agreement shall
                    be binding on their heirs, executors, successors and assigns, that the Agreement will be governed by
                    the laws of Texas, and that jurisdiction and venue for resolution of any dispute regarding this
                    Agreement shall lie in State District Courts in Gillespie, Llano, Kendall or Kerr Counties, Texas.
                    If any part of this Agreement is determined to be invalid or unenforceable, it does not affect the
                    validity of the remainder of this Agreement. The 2024 HDGS – Updated 5/22/2023 60 undersigned agrees
                    to the terms and conditions above, and acknowledges receipt of this Agreement.
                </p>
                <p>
                    <strong>HDGS CERTIFICATION OF ENTRY ACKNOWLEDGEMENT OF REGULATIONS: </strong>As a condition of my
                    entry, I hereby certify that I have read and am familiar with the rules and regulations of HDGS,
                    including the rules and regulations contained in the current “Hill District Grandstand Show General
                    Rules”, and that my entry, whether one or more, is owned and has been fed, fitted and exhibited in
                    accordance with the regulations. I agree that should this certification prove false, I can be
                    disqualified from further participation in all activities of HDGS and will forfeit all prizes,
                    premiums, privileges, and rights of an exhibitor, including the privilege of selling my entry or any
                    other entry in the youth auction. Should this certificate prove false after the entry or any other
                    entry has been sold by me in the youth auction, I agree that I shall forfeit that portion of the
                    price bid at the auction for the entry. We, the exhibitor, and parent/guardian, have not
                    administered to any entry entered by exhibitor, and to the best of our knowledge, the entry has not
                    received, any substance not approved by the U.S. Food and Drug Administration (FDA) and/or the U.S.
                    Department of agriculture (USDA) for consumption animals hereby being entered in the show. We
                    further certify that we have read and understood the drug residue avoidance policy as set forth in
                    the regulations in the “Hill District Grandstand Show General Rules”.
                    Veterinarians/Treatment/Medication: We agree to comply with the following for animals requiring
                    emergency treatment while an animal is at HDGS and hereby release HDGS from any and all damages
                    which might arise out of such treatment or related action: A. If an animal requires emergency
                    treatment while on show grounds, a licensed veterinarian will be allowed to administer any drug,
                    chemical or feed additive. An exhibitor may choose any licensed veterinarian, but the species
                    superintendent must be notified in advance, and he/she must be present during treatment. Animals
                    which are administered treatments with a withdrawal or extended elimination time period will be
                    disqualified from competition. All treatment costs are the responsibility of the exhibitor. B. We
                    also authorize HDGS to obtain samples from any exhibit for drug testing and agree to the following
                    disqualification policy of HDGS. Disqualification Policy: If an animal tests positive for drugs,
                    chemicals or feed additives as described above or in the drug residue avoidance policy and/or the
                    carcass is condemned at slaughter, HDGS reserves the right to disqualify any animal. If any animal
                    is disqualified HDGS may require the exhibitor to forfeit all auction sale and/or premium money and
                    forfeit all rights and privileges to exhibit at HDGS in the future and may also subject the
                    exhibitor to such other disciplinary actions as authorized in the regulations, specifically
                    including the drug residue avoidance policy. The class placing will not change if an animal is
                    disqualified. An exhibitor of an animal producing a positive sample of a substance not approved for
                    consumptive animals by federal or state law will be reported to the appropriate law enforcement
                    official for possible criminal prosecution. HDGS IS THE FINAL JUDGE OF COMPLIANCE WITH HDGS
                    REGULATIONS AND POLICIES.
                </p>
                <p>
                    <strong>USE OF LIKENESS CONSENT: </strong>I, the undersigned parent or guardian of the exhibitor
                    identified in the application do hereby acknowledge that I am aware of the intent of HDGS to utilize
                    photographs, video and other likenesses of exhibitors in the events of HDGS in the media, including
                    but not limited to magazines, newspapers, television, promotional literature, social media and in
                    HDGS programs. In consideration of the exhibitor being allowed the privilege of participating in the
                    events of HDGS, I do hereby grant to HDGS the right to use any such photograph, video and other
                    likenesses of my child or ward in the media.
                </p>
            </div>
        </div>
        <div class="row waiver-accept">
            <div class="col-md-10 d-flex align-items-center">
                <strong>I have read the Waiver Form and accept all the terms and conditions.</strong>
            </div>
            <div class="col-md-2 text-end">
                <p-button [rounded]="true" tabindex="-1" label="Accept" (onClick)="onWaiverAccept()" />
            </div>
        </div>
    </p-dialog>
</section>