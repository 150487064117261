import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { State } from '../models/state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Registration } from '../models/registration';
import CommonUtils from '../../shared/utils/common.utils';
import { RegistrationService } from '../registration.service';
import { NotificationService } from '../../shared/service/notification.service';
import { County } from '../models/county';
import { Chapter } from '../models/chapter';
import { OrgConfigs } from '../../shared/models/org-configs';
import { ConfirmationService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-exhibitor-info',
  templateUrl: './exhibitor-info.component.html',
  styleUrl: './exhibitor-info.component.scss'
})
export class ExhibitorInfoComponent implements OnInit {

  @Input() states: State[] = [];
  @Input() counties: County[] = [];
  @Input() chapters: Chapter[] = [];
  @Input() registration: Registration = {};
  @Input() orgId: number = 0;
  @Input() orgConfigs: OrgConfigs | undefined;

  today = new Date();
  minDate = new Date(2002, 0, 1);
  maxDate = new Date(2017, 11, 31);
  formGroup: FormGroup;
  showSSN: boolean = false;
  showDialog: boolean = false;

  constructor(
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private registrationService: RegistrationService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService) {

    this.formGroup = this.fb.group({
      orgId: [],
      firstName: [],
      lastName: [],
      exhibitorDOB: [],
      ssn: [],
      email: [],
      phone: [],
      address: [],
      city: [],
      state: [],
      zipCode: [],
      county: [],
      chapter: []
    });
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    const disabled: boolean = false;

    this.formGroup = this.fb.group({
      orgId: [this.orgId],
      firstName: [this.registration.firstName, [Validators.required]],
      lastName: [this.registration.lastName, [Validators.required]],
      exhibitorDOB: [this.registration.exhibitorDOB, [Validators.required]],
      email: [this.registration.email, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      phone: [this.registration.phone, [Validators.required]],
      address: [this.registration.address, [Validators.required]],
      city: [this.registration.city, [Validators.required]],
      state: [this.registration.state, [Validators.required]],
      zipCode: [this.registration.zipCode, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      county: [this.registration.county, [Validators.required]],
      chapter: [this.registration.chapter, [Validators.required]]
    });
  }

  getQualityCounts() {
    this.getRegistrationDetails();

    if (CommonUtils.isNotEmpty(this.registration.firstName)
      && CommonUtils.isNotEmpty(this.registration.lastName)
      && this.registration.exhibitorDOB != null) {

      this.registrationService.getQualityCounts(this.registration.firstName, this.registration.lastName, this.registration.exhibitorDOBStr).subscribe({
        next: qualityCounts => {
          this.registration.qualityCounts = {
            qcNumber: qualityCounts.qcNumber,
            currentGrade: qualityCounts.currentGrade,
            ffaCounty: qualityCounts.ffaCounty,
            level: qualityCounts.level,
            fourHCounty: qualityCounts.fourHCounty
          };

          this.buildForm();

          if (this.registration.firstName
            && this.registration.lastName
            && this.registration.ageDiscloserRequired
            && !this.registration.ageDiscloserAccepted) {
            this.showAgeDisclosures();
          }

          this.notificationService.clear();
        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 404) {
            this.showDialog = true;
          } else {
            this.registration.qualityCounts = undefined;
            this.notificationService.error();
          }
        }
      });
    }
  }

  resetQualityCounts() {
    this.registration.qualityCounts = {
      qcNumber: undefined,
      currentGrade: undefined,
      level: undefined
    };

    this.registration.ageDiscloserAccepted = false;
    this.registration.ageDiscloserRequired = false;

    this.showDialog = false;

    this.getRegistrationDetails();

    if (this.registration.firstName
      && this.registration.lastName
      && this.registration.ageDiscloserRequired
      && !this.registration.ageDiscloserAccepted) {
      this.showAgeDisclosures();
    }
  }

  getAddress() {
    this.getRegistrationDetails();

    if (CommonUtils.isNotEmpty(this.registration.address) &&
      CommonUtils.isNotEmpty(this.registration.city) &&
      CommonUtils.isNotEmpty(this.registration.state)) {

      this.registrationService.getAddress(this.registration.address, this.registration.city, this.registration.state).subscribe({
        next: address => {
          if (address) {
            //this.registration.address = address.streetAddress;
            //this.registration.city = address.city;
            //this.registration.state = address.state;
            this.registration.zipCode = address.zipCode;
            this.buildForm();
            this.notificationService.clear();
          } else {
            this.notificationService.warn("We couldn't verify given address. Please check address details.");
          }
        },
        error: err => {
          this.notificationService.warn("We couldn't verify given address. Please check address details.");
        }
      });
    }
  }

  validateForm(): boolean {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    this.changeDetector.detectChanges();

    return this.formGroup.valid;
  }

  getRegistrationDetails(): Registration {
    const reg = new Registration(this.formGroup.value);
    this.registration.orgId = reg.orgId;
    this.registration.firstName = CommonUtils.toTitleCase(reg.firstName);
    this.registration.lastName = CommonUtils.toTitleCase(reg.lastName);
    this.registration.fullName = `${this.registration.firstName} ${this.registration.lastName}`;
    this.registration.exhibitorDOB = reg.exhibitorDOB;
    this.registration.exhibitorDOBStr = CommonUtils.formatDateToString(reg.exhibitorDOB);
    this.registration.address = reg.address;
    this.registration.city = CommonUtils.toTitleCase(reg.city);
    this.registration.state = reg.state;
    this.registration.zipCode = reg.zipCode;
    this.registration.phone = reg.phone;
    this.registration.email = reg.email;
    this.registration.county = reg.county;
    this.registration.chapter = reg.chapter;
    this.registration.ageDiscloserRequired = this.orgConfigs && this.registration.exhibitorDOB ? CommonUtils.calculateAge(this.registration.exhibitorDOB) < this.orgConfigs.registrationAgeLimit : false;

    return this.registration;
  }

  showErrorMessage(formControlName: string): boolean {
    const fc = this.formGroup.controls[formControlName];

    return (fc.touched || fc.dirty) && fc.invalid;
  }

  showAgeDisclosures() {
    this.confirmationService.confirm({
      header: 'Age Disclosure',
      message: 'Accept to affirm this information is being provided by the parent or legal guardian.',
      defaultFocus: 'none',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button",
      rejectButtonStyleClass: "p-button-outlined p-button-danger",
      acceptLabel: 'Accept',
      rejectLabel: 'Reject',
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        this.registration.ageDiscloserAccepted = true;
      }
    });
  }

  onInput = CommonUtils.onDateInput;

  onCountyChange() {
    this.registration.liveStocks = [];
  }

}
