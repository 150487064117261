import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserModule } from "@angular/platform-browser";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { SharedModule } from "./shared/shared.module";
//import { AppHeaderComponent } from "./shared/components/app-header/app-header.component";
import { AppFooterComponent } from "./shared/components/app-footer/app-footer.component";
import { AppSidebarComponent } from "./shared/components/app-sidebar/app-sidebar.component";
import { RouterModule } from "@angular/router";
import { RegistrationComponent } from "./registration/registration.component";
import { RegistrationModule } from "./registration/registration.module";
import { AgTeachersModule } from "./ag-teachers/ag-teachers.module";

@NgModule({
    declarations: [
        AppComponent,
        //AppHeaderComponent,
        AppFooterComponent,
        AppSidebarComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RegistrationModule,
        RouterModule.forRoot([
            { path: '', redirectTo: 'registration', pathMatch: 'full' },
            { path: 'registration', component: RegistrationComponent },
            { path: '**', redirectTo: '/', pathMatch: 'full' }
        ], { scrollPositionRestoration: 'top' }),
        AgTeachersModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor() {
        console.log("App started.");
    }
}