import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppModule } from "./app/app.module";

//console.info('Angular CDK version', CDK_VERSION.full);
//console.info('Angular Material version', MAT_VERSION.full);

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    provideAnimations()
  ]
})
  .catch(err => console.error(err));