<section class="box">
    <div class="mb-auto scrollable">
        <div class="section-title">Exhibitors</div>
        <p-table [value]="exhibitors" styleClass="p-datatable-sm" scrollable="false" scrollHeight="flex" [lazy]="true"
            (onLazyLoad)="onSort($event)" [sortField]="'status'"
            [sortOrder]="this.tableOptions.sortOrder == 'asc' ? 1 : -1">
            <ng-template pTemplate="header">
                <tr class="head">
                    <th width="13%">Exhibitor ID</th>
                    <th>Full Name</th>
                    <th width="15%" class="text-end">Price</th>
                    <th width="18%" pSortableColumn="status">Status<p-sortIcon field="status" /></th>
                    <th width="10%" class="text-center">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-exhibitor let-ri="rowIndex">
                <tr class="greyed-out">
                    <td>{{exhibitor.id}}</td>
                    <td>{{exhibitor.fullName | titlecase}}</td>
                    <td class="text-end">
                        {{getTotalPrice(exhibitor.liveStocks) | currency:'USD'}}</td>
                    <td class="status text-center" [class]="exhibitor.status ?? 'Submitted'">
                        {{getStatus(exhibitor.status) ?? "Submitted"}}</td>
                    <td class="no-grey text-center">
                        <p-button [outlined]="false" icon="pi pi-eye" [text]="true" pTooltip="View Details"
                            tooltipPosition="left" (click)="viewExhibitor(exhibitor,'view')"></p-button>
                        <p-button [outlined]="false" icon="pi pi-envelope" [text]="true" pTooltip="Resend Email"
                            tooltipPosition="left" (click)="resendEmail(exhibitor.id, exhibitor.status)"></p-button>
                        <!--<p-splitButton label="View" (onClick)="viewExhibitor(exhibitor,'view')" [model]="actions"
                            menuStyleClass="menu" />-->
                    </td>
                </tr>
            </ng-template>
            <!--<ng-template pTemplate="footer">
                <tr>
                    <td colspan="5">
                        <div class="paginator-footer">
                            <p-paginator [rows]="rows" [totalRecords]="totalRecords"
                                (onPageChange)="onPageChange($event)" [rowsPerPageOptions]="[50,20,10]" appendTo="body">
                            </p-paginator>
                        </div>
                    </td>
                </tr>
            </ng-template>-->
        </p-table>
    </div>
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <p-paginator [first]="(tableOptions.pageNo) * tableOptions.pageSize" [rows]="tableOptions.pageSize"
                [totalRecords]="totalRecords" (onPageChange)="onPageChange($event)" [rowsPerPageOptions]="[50,20,10]"
                appendTo="body">
            </p-paginator>
        </div>
        <div class="col-md-2 d-flex align-items-center justify-content-end">
            <p-button label="Export" icon="pi pi-download" [text]="true" (onClick)="export()" />
        </div>
    </div>
</section>