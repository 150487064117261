import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { ExhibitorInfoComponent } from './exhibitor-info/exhibitor-info.component';
import { LivestocksComponent } from './livestocks/livestocks.component';
import { PaymentComponent } from './payment/payment.component';
import { SharedModule } from '../shared/shared.module';
import { RegistrationComponent } from './registration.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmationComponent } from './confirmation/confirmation.component';

@NgModule({
  declarations: [
    RegistrationComponent,
    ExhibitorInfoComponent,
    LivestocksComponent,
    AdditionalInfoComponent,
    PaymentComponent,
    ConfirmationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class RegistrationModule { }
