import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { AgTeachersService } from '../ag-teachers.service';
import { ExhibitorResponse } from '../models/exhibitors';
import { Router } from '@angular/router';
import { Registration } from "../../registration/models/registration";
import { NotificationService } from '../../shared/service/notification.service';
import { HttpErrorResponse } from '@angular/common/http';

interface TableOptions {
  pageNo: number;
  pageSize: number;
  sortOrder: string;
}

@Component({
  selector: 'app-entries',
  templateUrl: './entries.component.html',
  styleUrl: './entries.component.scss'
})
export class EntriesComponent implements OnInit, AfterViewInit {

  private agTeacherServices = inject(AgTeachersService);
  private notificationService = inject(NotificationService);
  private router = inject(Router);
  private orgId!: number;
  private chapterId!: number;

  exhibitorResponse?: ExhibitorResponse;
  exhibitors: Registration[] = [];
  totalRecords = 0;

  tableOptions: TableOptions = {
    pageNo: 0,
    pageSize: 10,
    sortOrder: ''
  };

  ngOnInit(): void {
    this.orgId = Number(sessionStorage.getItem("orgId"));
    this.chapterId = Number(sessionStorage.getItem("chapterId"));

    this.loadTableOptions();
  }

  ngAfterViewInit(): void {
    this.loadEntries();
  }

  private saveTableOptions() {
    sessionStorage.setItem('tableOptions', JSON.stringify(this.tableOptions));
  }

  private loadTableOptions(): TableOptions {
    const optionsString = sessionStorage.getItem('tableOptions');
    if (optionsString) {
      this.tableOptions = JSON.parse(optionsString) as TableOptions;
    }
    return this.tableOptions; // Return default if no options found
  }

  onSort(event?: any) {
    if (event.sortField) {
      this.tableOptions.sortOrder = event.sortOrder == 1 ? "asc" : "desc"; // Default sort order (1 for ascending, -1 for descending)
      this.saveTableOptions();
      this.loadEntries();
    }
  }

  //loadEntries(pageNo: number, pagelimit: number) {
  loadEntries() {
    const params = {
      orgId: this.orgId,
      chapterId: this.chapterId,
      pageNo: this.tableOptions.pageNo,
      pageSize: this.tableOptions.pageSize,
      orderBy: this.tableOptions.sortOrder
    };

    this.agTeacherServices.getExhibitorsByChapter(params).subscribe({
      next: response => {
        if (response) {
          this.exhibitorResponse = response;
          this.exhibitors = this.exhibitorResponse.items;
          this.totalRecords = this.exhibitorResponse.totalItems!;
          this.tableOptions.pageNo = this.exhibitorResponse.page!;
          this.tableOptions.pageSize = this.exhibitorResponse.pageSize!;
        }
      },
      error: err => {
        console.error(err);
        this.notificationService.error();
      }
    });
  }

  getTotalPrice(liveStocks: any[]): number {
    let sum = 0;
    liveStocks.forEach(x => {
      sum += x.calculatedPrice;
    });
    return sum;
  }

  getStatus(status?: string): string | undefined {
    switch (status) {
      case 'ApprovedWithCondition':
      case 'ReportedIssue':
        return 'Reported Issue';
      default:
        return status;
    }
  }

  onPageChange(event: any) {
    const page = event.first / event.rows;
    const pageSize = event.rows;
    this.tableOptions.pageNo = page;
    this.tableOptions.pageSize = pageSize;
    this.saveTableOptions();
    this.loadEntries();
  }

  viewExhibitor(exhibitor: any, action: string) {
    this.router.navigate(['/ag-teachers/', action, exhibitor.id]);
  }

  async export() {
    try {
      // handle 400 -> no records
      const response = await this.agTeacherServices.export(this.orgId, this.chapterId);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response.file);
      link.download = response.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e: any) {
      if (e instanceof HttpErrorResponse && e.status === 400) {
        this.notificationService.warn("There are no records to export!");
      }
      console.error(e);
      this.notificationService.error();
    }
  }

  resendEmail(exhibitorId: number, status: string) {
    switch (status?.toLowerCase()) {
      case 'reportedissue':
        this.agTeacherServices.resendReportedIssueEmail(this.orgId, exhibitorId).subscribe({
          next: res => {
            this.notificationService.success("Email sent successfully!");
          },
          error: err => {
            console.error(err);
            this.notificationService.error();
          }
        });
        break;
      case 'approved':
        this.agTeacherServices.resendApprovedEmail(this.orgId, exhibitorId).subscribe({
          next: res => {
            this.notificationService.success("Email sent successfully!");
          },
          error: err => {
            console.error(err);
            this.notificationService.error();
          }
        });
        break;
      case 'rejected':
        this.agTeacherServices.resendRejectedEmail(this.orgId, exhibitorId).subscribe({
          next: res => {
            this.notificationService.success("Email sent successfully!");
          },
          error: err => {
            console.error(err);
            this.notificationService.error();
          }
        });
        break;
      default:
        this.agTeacherServices.resendSubmittedEmail(this.orgId, exhibitorId).subscribe({
          next: res => {
            this.notificationService.success("Email sent successfully!");
          },
          error: err => {
            console.error(err);
            this.notificationService.error();
          }
        });
        break;
    }
  }

}
