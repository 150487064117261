import { inject, Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { DomSanitizer } from '@angular/platform-browser';
import CommonUtils from "../utils/common.utils";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private messageService = inject(MessageService);
    private sanitizer = inject(DomSanitizer);

    success(message: string, sticky: boolean = false, title: string = 'Success'): string {
        return this.notify('success', title, message, sticky);
    }

    info(message: string, sticky: boolean = false, title: string = 'Info'): string {
        return this.notify('info', title, message, sticky);
    }

    warn(message: string, sticky: boolean = false, title: string = 'Warn'): string {
        return this.notify('warn', title, message, sticky);
    }

    error(message: string = '', sticky: boolean = false, title: string = 'Error'): string {
        if (message == '') {
            message = 'Something went wrong! Please try again.';
        }
        return this.notify('error', title, message, sticky);
    }

    secondary(message: string, sticky: boolean = false, title: string = 'Secondary'): string {
        return this.notify('secondary', title, message, sticky);
    }

    clear(messageId?: string) {
        this.messageService.clear(messageId);
    }

    private notify(severity: string, title: string, message: string, sticky: boolean = false, duration: number = 4000): string {
        const htmlMessage = this.sanitizer.bypassSecurityTrustHtml(message);
        this.messageService.clear();
        const msgId = CommonUtils.generateUniqueId().toString();

        this.messageService.add({
            id: msgId.toString(),
            severity: severity,
            //summary: title,
            detail: message,
            closable: true,
            life: duration,
            sticky: sticky
        });

        return msgId;
    }

}