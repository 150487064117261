<div class="position-relative h-100">
    <img src="assets/side-panel-background.png" class="side-panel-background" alt="background" />
    <div class="row h-100">
        <div class="col-md d-flex flex-column">
            <div class="equal-section">
                <img src="assets/side-panel-top.png" class="side-panel-top" alt="top" />
                <img src="assets/hill-district.png" class="client-logo" alt="top" />
            </div>
            <div class="equal-section">
                <div class="dates">
                    <div class="date">
                        <div class="label">Registration</div>
                        <div class="value" [innerHTML]="registrationDates"></div>
                    </div>
                    <div class="date">
                        <div class="label">Schedule</div>
                        <div class="value" [innerHTML]="eventDates"></div>
                    </div>
                </div>
            </div>
            <div class="equal-section d-flex flex-column justify-content-end">
                <img src="assets/cfc-logo.png" class="cfc-logo" alt="top" />
                <img src="assets/side-panel-flag.png" class="side-panel-flag" alt="bottom" />
            </div>
        </div>
    </div>
</div>