import { inject, Injectable } from '@angular/core';
import states from '../../api/us-states.json';
import { State } from './models/state';
import { firstValueFrom, map, Observable } from 'rxjs';
import { Department } from './models/division-classes';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { QualityCounts, QualityCountsHeifers } from './models/quality-counts';
import { Address } from './models/address-response';
import { SigningDetails } from './models/signing-details';
import { OrgConfigs } from '../shared/models/org-configs';
import { RegistrationRequest, RegistrationResponse } from './models/registration';
import { County } from './models/county';
import { Chapter } from './models/chapter';
import { DownloadResponse } from '../shared/models/download-response';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    private http = inject(HttpClient);

    public getStates(): State[] {
        return states;
    }

    public getCounties(): Observable<County[]> {
        var url = `${environment.serviceHostName}/api/OrgConfig/counties`;

        return this.http.get<County[]>(url);
    }

    public getChapters(): Observable<Chapter[]> {
        var url = `${environment.serviceHostName}/api/OrgConfig/chapters`;

        return this.http.get<Chapter[]>(url);
    }

    public getOrgConfigsByAbbreviation(abbreviation: string): Promise<OrgConfigs> {
        var url = `${environment.serviceHostName}/api/OrgConfig/ByAbbreviation?abbreviation=${abbreviation}`;

        return firstValueFrom(this.http.get<OrgConfigs>(url));
    }

    public getQualityCounts(firstName?: string, lastName?: string, dateOfBirth?: string): Observable<QualityCounts> {
        var url = `${environment.serviceHostName}/QualityCounts?firstName=${firstName}&lastName=${lastName}&dateOfBirth=${dateOfBirth}`;

        return this.http.get<QualityCounts>(url);
    }

    public getQualityCountsHeifers(firstName?: string, lastName?: string): Observable<QualityCountsHeifers[]> {
        var url = `${environment.serviceHostName}/QualityCounts/Heifers?firstName=${firstName}&lastName=${lastName}`;

        return this.http.get<QualityCountsHeifers[]>(url);
    }

    public getAddress(address?: string, city?: string, state?: string): Observable<Address> {
        var url = `${environment.serviceHostName}/address?addressline1=${address}&city=${city}&state=${state}`;

        return this.http.get<Address>(url);
    }

    public getDivisionsAndClasses(orgId: number, county: string = ''): Observable<Department[]> {
        var url = `${environment.serviceHostName}/DivisionClass?orgId=${orgId}&county=${county}`;

        return this.http.get<Department[]>(url);
    }

    public getSigningUrl(payload: any): Observable<SigningDetails> {
        var url = `${environment.serviceHostName}/CapitalSinging/GetSigningUrl`;

        return this.http.post<SigningDetails>(url, payload);
    }

    public regenerateSigningUrl(transactionId: string, roleId: string): Observable<SigningDetails> {
        var url = `${environment.serviceHostName}/CapitalSinging/GetSigningUrl?transactionId=${transactionId}&roleId=${roleId}`;

        return this.http.get<SigningDetails>(url);
    }

    public getSigningStatus(transactionId: string): Observable<SigningDetails> {
        var url = `${environment.serviceHostName}/CapitalSinging/transactionstatus/${transactionId}`;

        return this.http.get<SigningDetails>(url);
    }

    public payAndRegister(registration: RegistrationRequest, w9File?: File): Promise<RegistrationResponse> {
        const formData: FormData = new FormData();

        // Append the file
        if (w9File) {
            formData.append('formW9File', w9File, w9File.name);
        }

        // Append the JSON data
        formData.append('registration', JSON.stringify(registration));

        var url = `${environment.serviceHostName}/Registration/Exhibitors`;

        return firstValueFrom(this.http.post<RegistrationResponse>(url, formData));
    }

    public async downloadConfirmation(orgId: number, id?: number): Promise<DownloadResponse> {
        var url = `${environment.serviceHostName}/Registration/downloadsummary?orgId=${orgId}&exhibitorId=${id}`;

        return firstValueFrom(this.http.get<any>(url, { observe: 'response', responseType: 'blob' as 'json' })
            .pipe(
                map((response) => {
                    var fileName = response.headers.get('x-file-name');
                    if (fileName) {
                        let data: DownloadResponse = {
                            file: response.body,
                            fileName: fileName
                        };

                        return Promise.resolve(data);
                    } else {
                        return Promise.reject();
                    }
                })
            ));
    }


}

