import { Component, inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AgTeachersService } from '../../ag-teachers.service';
import { LiveStockSummary } from '../../models/liveStockSummary';
import { ActivatedRoute, Router } from '@angular/router';
import { Registration } from "../../../registration/models/registration";
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from '../../../shared/service/notification.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import CommonUtils from '../../../shared/utils/common.utils';

@Component({
  selector: 'app-edit-entry',
  templateUrl: './edit-entry.component.html',
  styleUrl: './edit-entry.component.scss'
})
export class EditEntryComponent implements OnInit {

  private agTeacherServices = inject(AgTeachersService);
  private confirmationService = inject(ConfirmationService);
  private notificationService = inject(NotificationService);
  private route = inject(ActivatedRoute);
  private routes = inject(Router);

  exhibitor: Registration = {};
  liveStockSummary: LiveStockSummary = {};
  isLoading = true;
  isValidated = false;
  exhibitorId!: number;
  orgId!: number;
  chapterId!: number;

  @ViewChildren(OverlayPanel) overlayPanels!: QueryList<OverlayPanel>;

  ngOnInit(): void {
    this.exhibitorId = Number(this.route.snapshot.paramMap.get("id"));
    this.orgId = Number(sessionStorage.getItem("orgId"));
    this.chapterId = Number(sessionStorage.getItem("chapterId"));

    this.getExhibitor();
    this.getSummary();
  }

  getExhibitor() {
    this.agTeacherServices.getExhibitor(this.orgId, this.exhibitorId).subscribe({
      next: response => {
        if (response) {
          this.exhibitor = response;
          this.isValidated = this.exhibitor.status != null && !CommonUtils.equalsIgnoreCase('Submitted', this.exhibitor.status) ? true : false;
        }
      },
      error: err => {
        console.error(err);
        this.notificationService.error();
      }
    });
  }

  getSummary() {
    const params = {
      id: this.exhibitorId,
      orgId: this.orgId
    };

    this.agTeacherServices.liveStockSummary(params).subscribe({
      next: response => {
        if (response) {
          this.liveStockSummary = response;
          this.isLoading = false;
        }
      },
      error: err => {
        console.error(err);
        this.notificationService.error();
      }
    });
  }

  /*updateExhibitor() {
    this.agTeacherServices.updateExhibitor(this.exhibitor).subscribe({
      next: response => {
        console.log(response, "ok-update exhibitor");
      },
      error: err => {
        console.error(err);
        this.notificationService.error();
      }
    });
  }*/

  approveExhibitor() {
    this.confirmationService.confirm({
      header: 'Approve Confirmation',
      message: 'Do you want to approve this exhibitor?',
      defaultFocus: 'none',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button",
      rejectButtonStyleClass: "p-button-outlined p-button-danger",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        const params = {
          id: this.exhibitorId,
          chapterId: this.chapterId,
          comments: encodeURIComponent(this.exhibitor.approvalComments ?? '')
        };

        this.agTeacherServices.approveExhibitor(params).subscribe({
          next: response => {
            this.notificationService.success('Exhibitor approved!');
            this.back();
          },
          error: err => {
            console.error(err);
            this.notificationService.error();
          }
        });
      }
    });
  }

  approveWithConditions() {
    this.confirmationService.confirm({
      header: 'Approve with Conditions Confirmation',
      message: 'Do you want to approve this exhibitor with conditions?',
      defaultFocus: 'none',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button",
      rejectButtonStyleClass: "p-button-outlined p-button-danger",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        const params = {
          id: this.exhibitorId,
          chapterId: this.chapterId,
          comments: encodeURIComponent(this.exhibitor.approvalComments ?? '')
        };

        this.agTeacherServices.approveExhibitorWithConditions(params).subscribe({
          next: response => {
            console.log(response, "ok-approve exhibitor with conditions");
            this.notificationService.success('Exhibitor approved with conditions!');
            this.back();
          },
          error: err => {
            console.error(err);
            this.notificationService.error();
          }
        });
      }
    });
  }

  rejectExhibitor() {
    this.confirmationService.confirm({
      header: 'Reject Confirmation',
      message: 'Do you want to reject this exhibitor?',
      defaultFocus: 'none',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button",
      rejectButtonStyleClass: "p-button-outlined p-button-danger",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        const params = {
          id: this.exhibitorId,
          chapterId: this.chapterId,
          comments: encodeURIComponent(this.exhibitor.approvalComments ?? '')
        };

        this.agTeacherServices.rejectExhibitor(params).subscribe({
          next: response => {
            this.notificationService.success('Exhibitor rejected!');
            this.back();
          },
          error: err => {
            console.error(err);
            this.notificationService.error();
          }
        });
      }
    });
  }

  ReportedIssueExhibitor() {
    this.confirmationService.confirm({
      header: 'Report Issue Confirmation',
      message: 'Do you want to report issue for this exhibitor?',
      defaultFocus: 'none',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button",
      rejectButtonStyleClass: "p-button-outlined p-button-danger",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        const params = {
          id: this.exhibitorId,
          chapterId: this.chapterId,
          comments: encodeURIComponent(this.exhibitor.approvalComments ?? '')
        };

        this.agTeacherServices.ReportedIssueExhibitor(params).subscribe({
          next: response => {
            this.notificationService.success('Issue reported for exhibitor!');
            this.back();
          },
          error: err => {
            console.error(err);
            this.notificationService.error();
          }
        });
      }
    });
  }

  back() {
    this.routes.navigate(["/ag-teachers/entries"]);
  }

  showOverlayPanel(event: Event, index: number) {
    const overlayPanel = this.overlayPanels.toArray()[index];
    if (overlayPanel) {
      overlayPanel.toggle(event);
    }
  }

  getSubClassName = CommonUtils.getSubClassName;

}
