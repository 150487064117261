<section>
    <form id="frmLogin" [formGroup]="loginForm" autocomplete="off">
        <div class="row">
            <div class="col-md login-banner">Welcome</div>
        </div>
        <div class="row">
            <div class="col-md login-subtitle">Please enter your details below to continue</div>
        </div>
        <div class="row mt-3">
            <!--<div class="col-md">
                <p-floatLabel>
                    <input formControlName="userName" pInputText type="text" class="w-100"
                        [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('userName') }"
                        (keypress)="onKeyPress($event)" />
                    <label>Username<span class="red-text">*</span></label>
                </p-floatLabel>
            </div>-->
            <div class="col-md">
                <p-floatLabel>
                    <p-dropdown formControlName="chapter" [options]="chapters" [filter]="true" optionLabel="chapter"
                        optionValue="id" styleClass="w-100"
                        [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('chapter') }"
                        [panelStyle]="{ 'position': 'absolute', 'bottom': '100%', 'transform': 'translateY(-5px)' }"
                        appendTo="body" />
                    <label>Chapter</label>
                </p-floatLabel>
                <span *ngIf="showErrorMessage('chapter')" class="error-message">Please select Chapter</span>
            </div>
        </div>
        <div class="row mt-4">
            <!--<div class="col-md">
                <p-floatLabel>
                    <input formControlName="password" pInputText type="password" class="w-100"
                        [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('password') }"
                        (keypress)="onKeyPress($event)" />
                    <label>Password<span class="red-text">*</span></label>
                </p-floatLabel>
            </div>-->
            <div class="col-md">
                <p-floatLabel>
                    <input formControlName="accessCode" pInputText type="password" class="w-100"
                        [ngClass]="{ 'ng-invalid ng-dirty' :  showErrorMessage('accessCode') }"
                        (keypress)="onKeyPress($event)" />
                    <label>Access Code</label>
                </p-floatLabel>
                <span *ngIf="showErrorMessage('accessCode')" class="error-message">Please enter Access Code</span>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md d-flex justify-content-center">
                <p-button [disabled]="isSubmiting" [rounded]="true" (onClick)="onLogin()" label="Login" />
            </div>
        </div>
        <div *ngIf="errorMessage" class="row mt-3">
            <div class="col-md text-center red-text fw-bold">
                {{errorMessage}}
            </div>
        </div>
    </form>
</section>