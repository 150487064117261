import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(): boolean {
        const orgId = Number(sessionStorage.getItem("orgId"));
        const chapterId = Number(sessionStorage.getItem("chapterId"));

        if (!orgId || !chapterId) {
            this.router.navigate(['/ag-teachers/login']);
            return false;;
        } else {
            return true;
        }
    }
}
