import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from './service/notification.service';
import { StepperModule } from 'primeng/stepper';
import { StepsModule } from 'primeng/steps';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { SplitterModule } from 'primeng/splitter';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { ProgressBarModule } from 'primeng/progressbar';
import { DataService } from './service/dataService';
import { OverlayPanelModule } from 'primeng/overlaypanel';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FloatLabelModule,
    InputTextModule,
    InputMaskModule,
    InputNumberModule,
    InputTextareaModule,
    KeyFilterModule,
    CheckboxModule,
    DropdownModule,
    RadioButtonModule,
    ButtonModule,
    CalendarModule,
    PanelModule,
    ScrollPanelModule,
    ProgressSpinnerModule,
    ToastModule,
    StepperModule,
    StepsModule,
    ConfirmDialogModule,
    DialogModule,
    SplitterModule,
    TooltipModule,
    MessagesModule,
    ProgressBarModule,
    OverlayPanelModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    FloatLabelModule,
    InputTextModule,
    InputMaskModule,
    InputNumberModule,
    InputTextareaModule,
    KeyFilterModule,
    CheckboxModule,
    DropdownModule,
    RadioButtonModule,
    ButtonModule,
    CalendarModule,
    PanelModule,
    ScrollPanelModule,
    ProgressSpinnerModule,
    ToastModule,
    StepperModule,
    StepsModule,
    ConfirmDialogModule,
    DialogModule,
    SplitterModule,
    TooltipModule,
    MessagesModule,
    ProgressBarModule,
    OverlayPanelModule
  ],
  providers: [
    NotificationService,
    MessageService,
    ConfirmationService,
    DataService
  ]
})
export class SharedModule { }
